import React, { useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    FormControl,
    Stack,
    Grid,
    FormControlLabel,
    FormGroup,
    Divider,
    TextField,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    MenuItem,
    DialogActions,
    Button,
    CircularProgress,
    IconButton,
    Select,
    Box,
} from '@mui/material';
import ReactSelect, { components } from 'react-select';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { SwapHorizontalCircleOutlined, ExitToApp, SystemUpdateAlt, Close as CloseIcon, WarningAmber } from '@mui/icons-material';
import logo from '../../_images/rs_logo.png';
import '../../App.css';
import '../../_images/icons.css';
import {
    getWorkspaceList,
    updateUserActivity,
    checkFunction,
    getWorkspaceStatus,
    getSystemInfo,
    getHereVersion,
    checkSystemStatus,
    checkUsePickupDeliveryCapacity,
    checkContainerDeliverySetting,
    checkCurrencySetting,
} from '../../_actions/Global.actions';
import {
    createProject,
    getProjectRouteList,
    getProjects,
    createScenario,
    getScenarios,
    getWorkspaceDownload,
    getRouteList,
} from '../../_actions/MapPage.actions';
import { getRoutePlans } from '../../_actions/Manage.actions';
import { isMobile, serviceDayList as sdList, weekList, servicePatternList, allTabs } from '../../_helpers/common';
import MaterialReactTable from 'material-react-table';
import InfoIcon from '@mui/icons-material/ErrorOutline';
import { SiteAlertDialog, CustomSelectOption, CustomSelectValue, HtmlTooltip, IdleTimeOutModal, GlobalROContext, ROErrorDialog } from '../common';
import { LanguagePicker } from './LanguagePicker';
import { signalRService } from '../../_helpers/signalr-connection';

let COUNTDOWNTIMERENABLED = false;

export const Main = () => {
    const {
        setHereVersion,
        workspace,
        setWorkspace,
        serviceDay,
        setServiceDay,
        week,
        setWeek,
        frequency,
        setFrequency,
        routePlanId,
        setRoutePlanId,
        servicePattern,
        setServicePattern,
        project,
        setProject,
        setProjectObject,
        siteMode,
        setSiteMode,
        servicePatternView,
        runningJob,
        globalLoading,
        setGlobalLoading,
        routingProjects,
        setRoutingProjects,
        setRoutingProjectScenarios,
        setWorkspaceStatus,
        refreshProject,
        setRefreshProject,
        refreshScenario,
        setRefreshScenario,
        refreshRoutePlan,
        setRefreshRoutePlan,
        refreshWorkspaceStatus,
        setRefreshWorkspaceStatus,
        showWorkspaceList,
        showServiceDayList,
        showRoutePlanList,
        showWeekList,
        showFrequencyList,
        showServicePatternList,
        showDownloadWorkspace,
        openAlert,
        setOpenAlert,
        alertTitle,
        setAlertTitle,
        alertMessage,
        setAlertMessage,
        showContainerDelivery,
        setShowContainerDelivery,
        setContainerDeliveryEnabled,
        setCurrencySetting,
    } = useContext(GlobalROContext);

    const { t, i18n } = useTranslation();
    const location = useLocation();

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USER_FUNCTIONS) {
        sessionStorage.setItem('userFunctions', process.env.REACT_APP_USER_FUNCTIONS);
    }

    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo) userInfo = JSON.parse(userInfo);

    const [mdrWorkspaces, setMDRWorkspaces] = React.useState([]);
    const [projectList, setProjectList] = React.useState([]);

    const [workspaceList, setWorkspaceList] = React.useState([]);

    const [routePlanList, setRoutePlanList] = React.useState([]);

    const [routingProjectRouteList, setRoutingProjectRouteList] = React.useState([]);

    const [buttonLoading, setButtonLoading] = useState(false);

    const [openEnterPlannerDialog, setOpenEnterPlannerDialog] = React.useState(false);
    const [openPlannerMode, setOpenPlannerMode] = React.useState(null);

    const [projectName, setProjectName] = React.useState('');
    const [projectServiceDay, setProjectServiceDay] = React.useState(null);
    const [shareProject, setShareProject] = React.useState(false);

    const [projectRouteSelection, setProjectRouteSelection] = React.useState({});
    const [projectSelection, setProjectSelection] = React.useState({});

    const [disablePlanner, setDisablePlanner] = useState(true);
    const [systemInfo, setSystemInfo] = React.useState(null);

    const [maintenanceCountDown, setMaintenanceCountDown] = React.useState(null);
    const [maintenanceCountDownValue, setMaintenanceCountDownValue] = React.useState(null);

    const [language, setLanguage] = React.useState(localStorage.getItem('i18nextLng'));

    const WorkspaceId = useRef(workspace);

    const changeLanguage = (evt) => {
        setLanguage(evt.target.value);
        i18n.changeLanguage(evt.target.value);
    };

    const workspaceChangeHandler = (event) => {
        sessionStorage.setItem('workspaceInfo', JSON.stringify(event));
        setWorkspace(event);
        setRoutePlanId(null);
        if (mdrWorkspaces.includes(event.value)) {
            sessionStorage.setItem('mdr', true);
        } else {
            sessionStorage.setItem('mdr', false);
        }
    };

    const serviceDayChangeHandler = (event) => {
        sessionStorage.setItem('serviceDayInfo', JSON.stringify({ label: event.label, value: event.value }));
        setServiceDay(event);
    };

    const weekChangeHandler = (event) => {
        if (event) {
            setWeek(event.value);
        } else {
            setWeek(null);
        }
    };

    const frequencyChangeHandler = (event) => {
        if (event) {
            setFrequency(event.value);
        } else {
            setFrequency(null);
        }
    };

    const servicePatternChangeHandler = (event) => {
        if (event) {
            let s = [];
            event.forEach((sp) => {
                s.push(sp.value);
            });
            s.sort();
            setServicePattern(s);
        } else {
            setServicePattern([]);
        }
    };

    const routePlanChangeHandler = (event) => {
        if (event) {
            setRoutePlanId(event.value);
        } else {
            setRoutePlanId(null);
        }
    };

    const refreshWorkspaceList = () => {
        getWorkspaceList().then(
            (res) => {
                if (res.status === 200 && res.data.length > 0) {
                    let wsList = [];
                    res.data.forEach((r) => {
                        wsList.push({
                            label: r.Name,
                            value: r.Id,
                            type: r.WorkspaceType, // need to change to alleyAssignment once backend updated
                        });
                    });
                    wsList.sort((a, b) => {
                        return a.label.localeCompare(b.label, 'en', { numeric: true });
                    });

                    let mdr = [];
                    res.data.forEach((r) => {
                        if (r['WorkspaceType'] === 'Multi-Day') {
                            mdr.push(r.Id);
                        }
                    });
                    setMDRWorkspaces(mdr);

                    setWorkspaceList(wsList);

                    const savedWorkspace = sessionStorage.getItem('workspaceInfo');

                    if (savedWorkspace) {
                        let wsId = JSON.parse(savedWorkspace).value;
                        if (wsList.filter((w) => w.value === wsId).length > 0) {
                            if (WorkspaceId.current) {
                                if (WorkspaceId.current.value !== wsId) {
                                    setWorkspace(JSON.parse(savedWorkspace));
                                    if (mdr.includes(wsId)) {
                                        sessionStorage.setItem('mdr', true);
                                    } else {
                                        sessionStorage.setItem('mdr', false);
                                    }
                                }
                            } else {
                                setWorkspace(JSON.parse(savedWorkspace));
                                if (mdr.includes(wsId)) {
                                    sessionStorage.setItem('mdr', true);
                                } else {
                                    sessionStorage.setItem('mdr', false);
                                }
                            }
                        } else if (wsList.filter((w) => w.value === wsId).length === 0) {
                            if (workspace.value !== wsList[0].value) {
                                setWorkspace(wsList[0]);
                                if (mdr.includes(wsList[0].value)) {
                                    sessionStorage.setItem('mdr', true);
                                } else {
                                    sessionStorage.setItem('mdr', false);
                                }
                            }
                        }
                    } else {
                        if (!WorkspaceId.current || WorkspaceId.current.value !== wsList[0].value) {
                            setWorkspace(wsList[0]);
                            if (mdr.includes(wsList[0].value)) {
                                sessionStorage.setItem('mdr', true);
                            } else {
                                sessionStorage.setItem('mdr', false);
                            }
                            sessionStorage.setItem('workspaceInfo', JSON.stringify(wsList[0]));
                        }
                    }
                }
            },
            (err) => {
                setWorkspaceList([]);
            },
        );
    };

    const checkWorkspaceStatus = () => {
        getWorkspaceStatus().then((res) => {
            if (res.status === 200 && res.data.length > 0) {
                let data = res.data.filter((d) => !d.isDisabled || d.Status === 'In Progress');
                setWorkspaceStatus(data);
                const refreshingList = res.data.filter((d) => d.Status === 'In Queue' || d.Status === 'In Progress');
                if (refreshingList.length === 0) {
                    clearInterval(sessionStorage.getItem('CHECKWORKSPACESTATUSINTERVAL'));
                    sessionStorage.removeItem('CHECKWORKSPACESTATUSINTERVAL');
                    refreshWorkspaceList();
                } else if (refreshingList.length > 0) {
                    refreshWorkspaceList();
                }
            }
        });
    };

    const projectChangeHandler = (e) => {
        if (e.value) {
            const p = routingProjects.find((p) => p.ID == e.value);
            if (p) {
                const day = defaultServiceDayList.find((sd) => sd.label === p.ServiceDays);
                if (day) {
                    sessionStorage.setItem('serviceDayInfo', JSON.stringify(day));
                    setServiceDay(day);
                }
            }
            setProject(e.value);
            setProjectObject(p);
        } else {
            setProject(null);
            setProjectObject(null);
        }
    };

    const isAdmin = () => {
        const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
        return userInfo.adminToken;
    };

    React.useEffect(() => {
        checkSystemMaintenanceStatus();
        refreshWorkspaceList();
        if (sessionStorage.getItem('serviceDayInfo')) {
            setServiceDay(JSON.parse(sessionStorage.getItem('serviceDayInfo')));
        }
        if (isAdmin()) {
            getSystemInfo().then((res) => {
                if (res.status === 200 && res.data.Environment) {
                    setSystemInfo(res.data);
                }
            });
        }
        getHereVersion().then(
            (res) => {
                setHereVersion(res.data ? res.data.toLowerCase() : 'v3');
            },
            (err) => setHereVersion('v3'),
        );

        const userInfo = window.sessionStorage.getItem('userInfo');
        if (userInfo) {
            const user = JSON.parse(userInfo);
            window.Canny('identify', {
                appID: '66634e5d53f54dfccbd9b7f1',
                user: {
                    email: user['email'],
                    name: user['FirstName'],
                    id: user['UserID'],
                },
            });
        }
    }, []);

    React.useEffect(() => {
        if (refreshWorkspaceStatus) {
            setRefreshWorkspaceStatus(false);
            checkWorkspaceStatus();
            let inteval = setInterval(checkWorkspaceStatus, 10000);
            sessionStorage.setItem('CHECKWORKSPACESTATUSINTERVAL', inteval);
        }
    }, [refreshWorkspaceStatus]);

    React.useEffect(() => {
        if (refreshProject !== false) {
            getProjects(workspace.value, refreshProject).then(
                (res) => {
                    let projects = res.data;
                    setRoutingProjects(projects);
                    let projectList = [];
                    projects.forEach((p) => {
                        projectList.push({
                            label: p.SandboxName,
                            value: p.ID,
                            serviceDay: p.ServiceDays,
                            initRoutes: p.InitialRoutes,
                        });
                    });
                    projectList.sort((a, b) => a['label'].localeCompare(b['label'], 'en', { numeric: true }));
                    setProjectList(projectList);
                    if (project) {
                        setProject(project);
                        setProjectObject(projects.find((p) => p.ID === project));
                    }
                },
                () => {
                    setProjectList([]);
                    setProject(null);
                    setProjectObject(null);
                },
            );
            setRefreshProject(false);
        }
    }, [refreshProject]);

    React.useEffect(() => {
        if (refreshScenario) {
            getScenarios(workspace.value, project).then(
                (res) => {
                    let scenarios = [];
                    if (res && res.data) {
                        scenarios = res.data.map((s) => {
                            let temp = {
                                ...s,
                                NumServiceDays: s.ScenarioSummaryInfo['NumServiceDays'],
                                TotalCost: s.ScenarioSummaryInfo['TotalCost'],
                                TotalDemand: s.ScenarioSummaryInfo['TotalDemand'],
                                TotalDistance: s.ScenarioSummaryInfo['TotalDistance'],
                                TotalTime: s.ScenarioSummaryInfo['TotalTime'],
                                TotalTrips: s.ScenarioSummaryInfo['TotalTrips'],
                                TotalStops: s.ScenarioSummaryInfo['TotalStops'],
                                RouteCount: s.ScenarioSummaryInfo['RouteCount'],
                            };
                            delete temp['ScenarioSummaryInfo'];
                            return temp;
                        });
                    }
                    setRoutingProjectScenarios(scenarios);
                },
                () => {
                    setRoutingProjectScenarios([]);
                },
            );
            setRefreshScenario(false);
        }
    }, [refreshScenario]);

    React.useEffect(() => {
        if (workspace && refreshRoutePlan) {
            setRefreshRoutePlan(false);
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    if (res.status === 200 && res.data.length > 0) {
                        let rplist = [];
                        for (let i = 0; i < res.data.length; i++) {
                            rplist.push({
                                value: res.data[i].Id,
                                label: res.data[i].PlanName,
                            });
                        }
                        rplist.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                        setRoutePlanList(rplist);
                    } else {
                        setRoutePlanList([]);
                    }
                },
                (err) => {
                    setRoutePlanList([]);
                },
            );
        }
    }, [refreshRoutePlan]);

    React.useEffect(() => {
        WorkspaceId.current = workspace;
        if (workspace) {
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    if (res.status === 200 && res.data.length > 0) {
                        let rplist = [];
                        for (let i = 0; i < res.data.length; i++) {
                            rplist.push({
                                value: res.data[i].Id,
                                label: res.data[i].PlanName,
                            });
                        }
                        rplist.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                        setRoutePlanList(rplist);
                    } else {
                        setRoutePlanList([]);
                    }
                },
                (err) => {
                    setRoutePlanList([]);
                },
            );
            getProjects(workspace.value, true).then(
                (res) => {
                    setRoutingProjects(res.data);
                    let projectList = [];
                    res.data.forEach((p) => {
                        projectList.push({
                            label: p.SandboxName,
                            value: p.ID,
                            serviceDay: p.ServiceDays,
                            initRoutes: p.InitialRoutes,
                        });
                    });
                    projectList.sort((a, b) => a['label'].localeCompare(b['label'], 'en', { numeric: true }));
                    setProjectList(projectList);
                },
                () => {
                    setProjectList([]);
                    setProject(null);
                    setProjectObject(null);
                },
            );

            checkUsePickupDeliveryCapacity(workspace.value).then((res) => {
                setShowContainerDelivery(res.data === 1);
            });

            checkContainerDeliverySetting(workspace.value).then(
                (res) => {
                    setContainerDeliveryEnabled(res.data);
                },
                (err) => {
                    setContainerDeliveryEnabled(false);
                },
            );

            checkCurrencySetting(workspace.value).then(
                (res) => {
                    setCurrencySetting(res.data);
                },
                (err) => {
                    setCurrencySetting('USD');
                },
            );
        }
    }, [workspace]);

    React.useEffect(() => {
        if (siteMode === 'DISPATCHER' && workspace && serviceDay) {
            getProjects(workspace.value, true).then(
                (res) => {
                    if (res && res.data && res.data.length > 0) {
                        setDisablePlanner(false);
                    } else {
                        getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                            (res) => {
                                if (res && res.data && res.data.length === 0) {
                                    setDisablePlanner(true);
                                } else if (res && res.data && res.data.length > 0) {
                                    setDisablePlanner(false);
                                }
                            },
                            () => {
                                setDisablePlanner(true);
                            },
                        );
                    }
                },
                (error) => {
                    getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                        (res) => {
                            if (res && res.data && res.data.length === 0) {
                                setDisablePlanner(true);
                            } else if (res && res.data && res.data.length > 0) {
                                setDisablePlanner(false);
                            }
                        },
                        () => {
                            setDisablePlanner(true);
                        },
                    );
                },
            );
        }
    }, [workspace, serviceDay]);

    React.useEffect(() => {
        if (project !== null) {
            sessionStorage.setItem('project', project);
            getScenarios(workspace.value, project).then(
                (res) => {
                    let scenarios = [];
                    if (res && res.data) {
                        scenarios = res.data.map((s) => {
                            let temp = {
                                ...s,
                                NumServiceDays: s.ScenarioSummaryInfo['NumServiceDays'],
                                TotalCost: s.ScenarioSummaryInfo['TotalCost'],
                                TotalDemand: s.ScenarioSummaryInfo['TotalDemand'],
                                TotalDistance: s.ScenarioSummaryInfo['TotalDistance'],
                                TotalTime: s.ScenarioSummaryInfo['TotalTime'],
                                TotalTrips: s.ScenarioSummaryInfo['TotalTrips'],
                                TotalStops: s.ScenarioSummaryInfo['TotalStops'],
                                RouteCount: s.ScenarioSummaryInfo['RouteCount'],
                            };
                            delete temp['ScenarioSummaryInfo'];
                            return temp;
                        });
                    }
                    setRoutingProjectScenarios(scenarios);
                },
                () => {
                    setRoutingProjectScenarios([]);
                },
            );
        } else {
            sessionStorage.removeItem('project');
        }
        if (workspace) {
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    if (res.status === 200 && res.data.length > 0) {
                        let rplist = [];
                        for (let i = 0; i < res.data.length; i++) {
                            rplist.push({
                                value: res.data[i].Id,
                                label: res.data[i].PlanName,
                            });
                        }
                        rplist.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                        setRoutePlanList(rplist);
                    } else {
                        setRoutePlanList([]);
                    }
                },
                (err) => {
                    setRoutePlanList([]);
                },
            );
        }
    }, [project]);

    React.useEffect(() => {
        sessionStorage.setItem('siteMode', siteMode);
    }, [siteMode]);

    React.useEffect(() => {
        sessionStorage.setItem('servicePatternView', servicePatternView);
    }, [servicePatternView]);

    React.useEffect(() => {
        setProjectServiceDay(null);
        if (openPlannerMode === 'CREATE') {
            setProjectServiceDay(serviceDay);
            setShareProject(false);
        }
    }, [openPlannerMode]);

    React.useEffect(() => {
        if (projectServiceDay && projectServiceDay.value !== null) {
            setProjectRouteSelection({});
            getProjectRouteList(workspace.value, projectServiceDay.value).then(
                (res) => {
                    setRoutingProjectRouteList(res.data);
                },
                () => {
                    setRoutingProjectRouteList([]);
                },
            );
        }
    }, [projectServiceDay]);

    const switchUser = () => {
        if (window.sessionStorage['UserSettingInfo']) {
            sessionStorage.removeItem('UserSettingInfo');
        }
        if (window.sessionStorage['workspaceInfo']) {
            sessionStorage.removeItem('workspaceInfo');
        }
        if (window.sessionStorage['serviceDayInfo']) {
            sessionStorage.removeItem('serviceDayInfo');
        }
        if (window.sessionStorage['userInfo']) {
            let oldInfo = JSON.parse(window.sessionStorage['userInfo']);
            let newInfo = {
                adminToken: oldInfo.adminToken,
                userName: oldInfo.UserID,
                password: oldInfo.password,
                FunctionID: oldInfo.FunctionID,
                UserID: oldInfo.UserID,
                ClientID: -1,
            };
            window.sessionStorage['userInfo'] = JSON.stringify(newInfo);
        }
        window.location.replace(window.location.origin);
    };

    const logout = () => {
        updateUserActivity({ ActionId: 2, WorkspaceId: workspace?.value });
        window.token = '';
        window.ClientID = '';
        window.UserID = '';
        window.sessionStorage.clear();
        window.location.replace(window.location.origin);
    };

    const handleDownloadWorkspace = () => {
        setButtonLoading(true);
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating export...Please wait...</h1>');
        }

        getWorkspaceDownload(workspace.value, serviceDay.value).then(
            (res) => {
                if (res.status === 200) {
                    setButtonLoading(false);
                    let filename = '';
                    let disposition = res.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    let blob = new Blob([res.data], { type: 'application/zip' });
                    if (blob.size > 0) {
                        let url = window.URL.createObjectURL(blob);
                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.location = url;
                        } else {
                            if (!window.navigator.msSaveOrOpenBlob) {
                                let anchor = document.createElement('a');
                                anchor.href = url;
                                anchor.download = filename + '.zip';
                                document.body.appendChild(anchor);
                                anchor.click();
                                document.body.removeChild(anchor);
                            } else {
                                window.navigator.msSaveOrOpenBlob(blob, filename + '.zip');
                            }
                        }
                        setTimeout(() => window.URL.revokeObjectURL(url), 0);
                    } else {
                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.document.write('<h1>No content under selected service day.</h1>');
                        }
                    }
                } else if (res.status === 204) {
                    setButtonLoading(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage('No content under selected service day.');
                }
            },
            (err) => {
                setButtonLoading(false);
                const enc = new TextDecoder('utf-8');
                const arr = new Uint8Array(err.response.data);
                const str = enc.decode(arr);
                //const jsn = JSON.parse(str);

                setOpenAlert(true);
                setAlertTitle('Error');
                setAlertMessage(`${str}`);
            },
        );
    };

    const handleSiteModeChange = (e) => {
        if (e.target.value === 'DISPATCHER') {
            setSiteMode(e.target.value);
            setProject(null);
            setProjectObject(null);
        } else if (e.target.value === 'PLANNER') {
            setRoutingProjectRouteList([]);
            setOpenEnterPlannerDialog(true);
            if (routingProjects && routingProjects.length > 0) {
                setOpenPlannerMode('OPEN');
            } else {
                setOpenPlannerMode('CREATE');
            }
        }
    };

    const handleEnterPlannerDialogClose = (mode) => {
        if (mode === 'CREATE') {
            let selectedRoutes = [];
            Object.keys(projectRouteSelection).map((key, index) => selectedRoutes.push(key));

            let data = {
                SandboxName: projectName,
                ServiceDays: projectServiceDay.label,
                SlFilterRoutes: selectedRoutes.toString(),
                Share: shareProject,
            };

            const scenarioData = {
                ScenarioName: 'Initial scenario',
            };
            setGlobalLoading(true);

            createProject(workspace.value, data).then(
                (projectRes) => {
                    const sandboxId = projectRes.data.ID;
                    signalRService.startConnection(workspace.value, sandboxId);
                    signalRService.onReceiveMessage((msg) => {
                        console.log(msg);
                        if (msg) {
                            let message = JSON.parse(msg);
                            if (!message.Disabled) {
                                if (message.Errors) {
                                    setGlobalLoading(false);
                                    setOpenAlert(true);
                                    setAlertTitle('Error');
                                    setAlertMessage(JSON.stringify(message.Errors));
                                    const logData = {
                                        WorkspaceId: workspace.value,
                                        ActionId: 1202,
                                        Mode: siteMode,
                                        Success: false,
                                        Metadata: data.SandboxName,
                                    };
                                    updateUserActivity(logData);
                                } else {
                                    setGlobalLoading(false);
                                    createScenario(workspace.value, sandboxId, scenarioData).then(
                                        (result) => {
                                            getProjects(workspace.value, true).then(
                                                (res) => {
                                                    setGlobalLoading(false);
                                                    setRoutingProjects(res.data);
                                                    let projectList = [];
                                                    res.data.forEach((p) => {
                                                        projectList.push({
                                                            label: p.SandboxName,
                                                            value: p.ID,
                                                            serviceDay: p.ServiceDays,
                                                            initRoutes: p.InitialRoutes,
                                                        });
                                                    });
                                                    projectList.sort((a, b) => a['label'].localeCompare(b['label'], 'en', { numeric: true }));
                                                    setProjectList(projectList);
                                                    setSiteMode('PLANNER');
                                                    const p = res.data.find((p) => p.ID == sandboxId);
                                                    const day = defaultServiceDayList.find((sd) => sd.label === p.ServiceDays);
                                                    if (day) {
                                                        sessionStorage.setItem('serviceDayInfo', JSON.stringify(day));
                                                        setServiceDay(day);
                                                    }
                                                    setProject(sandboxId);
                                                    setProjectObject(p);
                                                    setOpenEnterPlannerDialog(false);
                                                    setProjectName('');
                                                    setOpenPlannerMode(null);
                                                    setProjectRouteSelection({});
                                                    setProjectSelection({});
                                                    setShareProject(false);
                                                },
                                                () => {
                                                    setGlobalLoading(false);
                                                    setProjectList([]);
                                                    setOpenEnterPlannerDialog(false);
                                                    setProjectName('');
                                                    setOpenPlannerMode(null);
                                                    setProjectRouteSelection({});
                                                    setProjectSelection({});
                                                    setShareProject(false);
                                                },
                                            );
                                        },
                                        (error) => {
                                            setGlobalLoading(false);
                                            setOpenAlert(true);
                                            setAlertTitle('Error');
                                            setAlertMessage(JSON.stringify(error.response.data));
                                            getProjects(workspace.value, true).then(
                                                (res) => {
                                                    let projects = res.data;
                                                    setRoutingProjects(projects);
                                                    let projectList = [];
                                                    projects.forEach((p) => {
                                                        projectList.push({
                                                            label: p.SandboxName,
                                                            value: p.ID,
                                                            serviceDay: p.ServiceDays,
                                                            initRoutes: p.InitialRoutes,
                                                        });
                                                    });
                                                    projectList.sort((a, b) => a['label'].localeCompare(b['label'], 'en', { numeric: true }));
                                                    setProjectList(projectList);
                                                    setProject(sandboxId);
                                                    setProjectObject(projects.find((p) => p.ID === sandboxId));
                                                    setOpenEnterPlannerDialog(false);
                                                    setProjectName('');
                                                    setOpenPlannerMode(null);
                                                    setProjectRouteSelection({});
                                                    setProjectSelection({});
                                                    setSiteMode('PLANNER');
                                                    setShareProject(false);
                                                },
                                                () => {
                                                    setProjectList([]);
                                                    setOpenEnterPlannerDialog(false);
                                                    setProjectName('');
                                                    setOpenPlannerMode(null);
                                                    setProjectRouteSelection({});
                                                    setProjectSelection({});
                                                    setShareProject(false);
                                                },
                                            );
                                        },
                                    );
                                }
                                signalRService.disconnect();
                            }
                        }
                    });

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1202,
                        Mode: siteMode,
                        Success: true,
                        Metadata: data.SandboxName,
                    };
                    updateUserActivity(logData);
                },
                (err) => {
                    setGlobalLoading(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1202,
                        Mode: siteMode,
                        Success: false,
                        Metadata: data.SandboxName,
                    };
                    updateUserActivity(logData);
                },
            );
        } else if (mode === 'OPEN') {
            projectChangeHandler({ value: Number(Object.keys(projectSelection)[0]) });
            setSiteMode('PLANNER');
            setOpenEnterPlannerDialog(false);
            setProjectName('');
            setOpenPlannerMode(null);
            setProjectRouteSelection({});
            setProjectSelection({});
        } else if (mode === 'CANCEL') {
            setOpenEnterPlannerDialog(false);
            setProjectName('');
            setOpenPlannerMode(null);
            setProjectRouteSelection({});
            setProjectSelection({});
            setShareProject(false);
        }
    };

    const handleChangeProjectName = (e) => {
        setProjectName(e.target.value);
    };

    const handleChangeShareProject = (e) => {
        setShareProject(e.target.checked);
    };

    const handleProjectServiceDayChanged = (e) => {
        setProjectServiceDay(e);
    };

    const checkServiceDayAvailability = () => {
        if (siteMode === 'PLANNER') {
            if (project) {
                let currentProject = projectList.find((p) => p.value === project);
                if (currentProject) {
                    return currentProject['serviceDay'] !== 'All Days';
                }
            }
        }
        return false;
    };

    const checkSystemMaintenanceStatus = () => {
        checkSystemStatus().then(
            (res) => {
                if (res.data.Available && res.data.Message) {
                    setOpenAlert(true);
                    setAlertTitle('');
                    setAlertMessage(res.data.Message);
                }
                if (res.data.$WarningBuffer) {
                    let countDown = res.data.WarningBuffer.substr(3, 5);
                    let timeArray = countDown.split(/[:]+/);
                    setMaintenanceCountDownValue(timeArray[0] + ':' + timeArray[1]);
                    setMaintenanceCountDown(parseInt(timeArray[0], 10) + 1);
                    startCountDownTimer();
                    setCountDownTimer();
                } else {
                    setMaintenanceCountDown(null);
                }
            },
            () => {
                stopCountDownTimer();
            },
        );
        setInterval(() => {
            checkSystemStatus().then(
                (res) => {
                    if (res.data.WarningBuffer) {
                        let countDown = res.data.WarningBuffer.substr(3, 5);
                        let timeArray = countDown.split(/[:]+/);
                        setMaintenanceCountDownValue(timeArray[0] + ':' + timeArray[1]);
                        setMaintenanceCountDown(parseInt(timeArray[0], 10) + 1);
                        if (!COUNTDOWNTIMERENABLED) {
                            startCountDownTimer();
                            setCountDownTimer();
                        }
                    } else {
                        stopCountDownTimer();
                    }
                },
                () => {
                    stopCountDownTimer();
                },
            );
        }, 60000);
    };

    const setCountDownTimer = () => {
        if (COUNTDOWNTIMERENABLED) {
            let timeArray = maintenanceCountDownValue.split(/[:]+/);
            let m = timeArray[0];
            if (m < 0) {
                setMaintenanceCountDownValue('00:00');
                setMaintenanceCountDown('0');
                window.sessionStorage.clear();
                document.cookie = 'userInfo=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                setTimeout(() => {
                    window.location.replace('index.html');
                }, 3000);
            } else {
                let s = checkSecond(timeArray[1] - 1);
                if (s == 59) {
                    m = m - 1;
                }
                if (m < 0) {
                    setMaintenanceCountDownValue('00:00');
                    setMaintenanceCountDown('0');
                    window.sessionStorage.clear();
                    document.cookie = 'userInfo=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    setTimeout(() => {
                        window.location.replace('index.html');
                    }, 3000);
                } else {
                    setMaintenanceCountDownValue(m + ':' + s);
                    setMaintenanceCountDown(parseInt(m, 10) + 1);
                    setTimeout(setCountDownTimer, 1000);
                }
            }
        }
    };

    const startCountDownTimer = () => {
        COUNTDOWNTIMERENABLED = true;
    };

    const stopCountDownTimer = () => {
        COUNTDOWNTIMERENABLED = false;
    };

    const checkSecond = (sec) => {
        if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
        }
        if (sec < 0) {
            sec = '59';
        }
        return sec;
    };

    const defaultServiceDayList = [
        { value: 0, label: t('Global.DayOfWeek.AllDays'), display: true },
        { value: 1, label: t('Global.DayOfWeek.Monday'), display: true },
        { value: 2, label: t('Global.DayOfWeek.Tuesday'), display: true },
        { value: 3, label: t('Global.DayOfWeek.Wednesday'), display: true },
        { value: 4, label: t('Global.DayOfWeek.Thursday'), display: true },
        { value: 5, label: t('Global.DayOfWeek.Friday'), display: true },
        { value: 6, label: t('Global.DayOfWeek.Saturday'), display: true },
        { value: 7, label: t('Global.DayOfWeek.Sunday'), display: true },
    ];

    const frequencyList = [
        { value: '1', label: t('Global.Frequencies.1x') },
        { value: '2', label: t('Global.Frequencies.2x') },
        { value: '3', label: t('Global.Frequencies.3x') },
        { value: '4', label: t('Global.Frequencies.4x') },
        { value: '5', label: t('Global.Frequencies.5x') },
        { value: '6', label: t('Global.Frequencies.6x') },
        { value: '7', label: t('Global.Frequencies.Daily') },
        { value: '8', label: t('Global.Frequencies.Weekly') },
        { value: '9', label: t('Global.Frequencies.BiWeekly') },
        { value: '10', label: t('Global.Frequencies.Monthly') },
        { value: '11', label: t('Global.Frequencies.LessThanMonthly') },
    ];

    return (
        <>
            <div style={{ flexGrow: 1, width: '100%', height: '100%', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    <img
                        src={logo}
                        height={50}
                        alt={'RouteSmart Public Works'}
                        style={{ padding: '5px' }}
                    />
                    {systemInfo?.Environment ? (
                        <Box
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#EE8A22',
                                color: 'white',
                                borderRadius: '4px',
                                padding: '5px 20px',
                                margin: '15px',
                                fontSize: '22px',
                                marginLeft: 'auto',
                            }}
                        >
                            {systemInfo?.Environment}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                textAlign: 'center',
                                color: '#739DCA',
                                padding: '5px 20px',
                                margin: '15px',
                                fontSize: '16px',
                                fontWeight: 600,
                                marginLeft: 'auto',
                            }}
                        >
                            For RouteSmart Online Technical Support - Please Call 1-800-977-7284
                        </Box>
                    )}
                    {maintenanceCountDown && (
                        <Box
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#F00',
                                color: '#FFF',
                                padding: '5px 20px',
                                margin: '15px',
                                fontSize: '16px',
                                fontWeight: 600,
                                marginLeft: 'auto',
                                position: 'absolute',
                                width: '100%',
                                height: '30px',
                                top: '-2px',
                            }}
                        >
                            <span>RouteSmart Online will be unavailable in {maintenanceCountDown} minute(s) for required maintenance...</span>
                        </Box>
                    )}
                    <div style={{ display: 'flex', marginLeft: 'auto', direction: 'rtl' }}>
                        <IconButton
                            style={{ marginLeft: 'auto!important', order: 1, padding: '8px' }}
                            color='default'
                            title={t('Global.Actions.Logout')}
                            component='span'
                            onClick={logout}
                        >
                            <ExitToApp />
                        </IconButton>

                        {(window.location.hostname.includes('admin') || window.location.hostname.includes('localhost')) && (
                            <IconButton
                                style={{ marginLeft: 'auto!important', order: 2, padding: '8px' }}
                                color='default'
                                title={t('Global.Actions.SwitchUser')}
                                component='span'
                                onClick={() => switchUser()}
                            >
                                <SwapHorizontalCircleOutlined />
                            </IconButton>
                        )}
                        <Typography
                            variant='overline'
                            component='span'
                            style={{ marginTop: '12px', whiteSpace: 'nowrap', order: 3, padding: '8px' }}
                        >
                            {t('Global.Labels.Welcome')} {userInfo.FirstName}{' '}
                        </Typography>

                        {window.location.hostname.includes('localhost') && (
                            <LanguagePicker
                                language={language}
                                changeLanguage={changeLanguage}
                            />
                        )}

                        <a
                            data-canny-link
                            href='https://routesmart-technologies-inc.canny.io/'
                            target='_blank'
                            style={{ order: 4, marginTop: '23px', color: '#f16225', fontWeight: 600 }}
                        >
                            Give Feedback
                        </a>
                    </div>
                </div>

                <AppBar
                    id='app_bar'
                    style={{ display: 'flex', flexFlow: 'row wrap' }}
                    position='static'
                    color='default'
                >
                    <Tabs
                        value={allTabs.findIndex((e) => e.path.includes(window.location.pathname))}
                        indicatorColor='primary'
                    >
                        <Tab
                            style={{ display: checkFunction(209) ? undefined : 'none', minWidth: '50px', maxWidth: '50px' }}
                            title={t('Map.Title')}
                            icon={<span className={'RS_MENU_ICONS RS_MAP'} />}
                            key={0}
                            component={Link}
                            to={allTabs[0].path}
                        />
                        <Tab
                            style={{ display: checkFunction(1400) ? undefined : 'none', minWidth: '50px', maxWidth: '50px' }}
                            title={t('RoutePlans.Title')}
                            icon={<span className={'RS_MENU_ICONS RS_ROUTE'} />}
                            key={1}
                            component={Link}
                            to={allTabs[1].path}
                        />
                        <Tab
                            style={{
                                display: checkFunction(904) && siteMode === 'DISPATCHER' ? undefined : 'none',
                                minWidth: '50px',
                                maxWidth: '50px',
                            }}
                            title={t('AccountManagement.Title')}
                            icon={<span className={'RS_MENU_ICONS RS_ACCOUNT'} />}
                            key={5}
                            component={Link}
                            to={allTabs[2].path}
                        />
                        <Tab
                            style={{
                                display: siteMode === 'DISPATCHER' && checkFunction(1500) ? undefined : 'none',
                                minWidth: '50px',
                                maxWidth: '50px',
                            }}
                            title={t('Administration.Title')}
                            icon={<span className={'RS_MENU_ICONS RS_MANAGE'} />}
                            key={6}
                            component={Link}
                            to={allTabs[3].path}
                        />
                    </Tabs>
                    <Divider
                        orientation='vertical'
                        flexItem
                    />
                    {showWorkspaceList && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                {showDownloadWorkspace && checkFunction(801) && siteMode === 'DISPATCHER' && (
                                    <div title='Export to RouteSmart for ArcGIS Pro®'>
                                        <SystemUpdateAlt
                                            style={
                                                buttonLoading || !workspace?.value || serviceDay?.value === 0
                                                    ? {
                                                          color: '#ddd',
                                                          fontSize: '16px',
                                                          marginTop: '10px',
                                                          marginLeft: '5px',
                                                          cursor: 'not-allowed',
                                                          pointerEvents: 'none',
                                                      }
                                                    : {
                                                          color: '#888',
                                                          fontSize: '16px',
                                                          marginTop: '10px',
                                                          marginLeft: '5px',
                                                          cursor: 'pointer',
                                                      }
                                            }
                                            onClick={() => handleDownloadWorkspace()}
                                            title='Export to RouteSmart Desktop'
                                        />
                                        {buttonLoading && (
                                            <CircularProgress
                                                size={16}
                                                sx={{
                                                    position: 'absolute',
                                                    marginTop: '10px',
                                                    marginLeft: '-16px',
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                                <FormControlLabel
                                    value='start'
                                    control={
                                        <div style={{ width: '150px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                isDisabled={siteMode === 'PLANNER'}
                                                name='Workspace_Select'
                                                id='Workspace_Select'
                                                value={workspace ? workspaceList.find((w) => w.value == workspace.value) : null}
                                                onChange={workspaceChangeHandler}
                                                options={workspaceList}
                                                menuPortalTarget={document.body}
                                                components={{ SingleValue: CustomSelectValue, Option: CustomSelectOption }}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.Workspace')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {showServiceDayList && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='start'
                                    control={
                                        <div style={{ width: '125px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                placeholder={'All'}
                                                isDisabled={checkServiceDayAvailability()}
                                                name='Serviceday_Select'
                                                id='Serviceday_Select'
                                                value={serviceDay ? defaultServiceDayList.find((s) => s.value == serviceDay.value) : null}
                                                onChange={serviceDayChangeHandler}
                                                options={defaultServiceDayList}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        display: state.data.display ? '' : 'none',
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.ServiceDay')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {showWeekList && sessionStorage.getItem('mdr') === 'true' && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='Week'
                                    control={
                                        <div style={{ width: '110px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                placeholder={'All'}
                                                isSearchable={true}
                                                name='Week_Select'
                                                id='Week_Select'
                                                value={weekList.find((r) => r.value == week)}
                                                onChange={weekChangeHandler}
                                                options={weekList}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.Week')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {showFrequencyList && sessionStorage.getItem('mdr') === 'true' && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='Frequency'
                                    control={
                                        <div style={{ width: '180px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder={'All'}
                                                name='Frequency_Select'
                                                id='Frequency_Select'
                                                value={
                                                    servicePatternView
                                                        ? frequency
                                                            ? frequencyList.find((r) => r.value == frequency)
                                                            : null
                                                        : frequency
                                                        ? frequencyList.filter((f) => f.value > 7).find((r) => r.value == frequency)
                                                        : null
                                                }
                                                onChange={frequencyChangeHandler}
                                                options={servicePatternView ? frequencyList : frequencyList.filter((f) => f.value > 7)}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.Frequency')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {showServicePatternList && sessionStorage.getItem('mdr') === 'true' && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='ServicePattern'
                                    control={
                                        <div style={{ width: '412px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={false}
                                                isMulti={true}
                                                placeholder={'All'}
                                                name='ServicePattern_Select'
                                                id='ServicePattern_Select'
                                                value={servicePatternList.filter((r) => servicePattern.includes(r.value))}
                                                onChange={servicePatternChangeHandler}
                                                options={servicePatternList}
                                                menuPortalTarget={document.body}
                                                components={{ Option: ServicePatternOption, MultiValueLabel: ServicePatternValueLabel }}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.ServicePattern')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {showRoutePlanList && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='Route Plan'
                                    control={
                                        <div style={{ width: '150px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                name='RoutePlan_Select'
                                                id='RoutePlan_Select'
                                                value={routePlanId ? routePlanList.find((r) => r.value == routePlanId) : null}
                                                onChange={routePlanChangeHandler}
                                                options={routePlanList}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.RoutePlan')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {siteMode === 'PLANNER' && (
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <div style={{ width: '150px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                name='project_select'
                                                id='project_select'
                                                value={projectList.find((p) => p.value == project)}
                                                onChange={projectChangeHandler}
                                                options={projectList}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '11px' }}>{t('Global.Filters.Project')}:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {(location.pathname === '/map' || location.pathname === '/manage') && (
                        <FormControl sx={{ minWidth: 120, margin: '10px 25px 0 auto' }}>
                            <FormControlLabel
                                control={
                                    <Select
                                        value={siteMode}
                                        onChange={handleSiteModeChange}
                                        displayEmpty
                                        disabled={disablePlanner || !checkFunction(1200)}
                                        sx={{ height: 36, fontSize: '10px' }}
                                    >
                                        <MenuItem
                                            style={{ fontSize: '10px' }}
                                            value={'DISPATCHER'}
                                        >
                                            {t('Terms.SiteMode.Dispatcher')}
                                        </MenuItem>
                                        <MenuItem
                                            style={{ fontSize: '10px' }}
                                            value={'PLANNER'}
                                        >
                                            {t('Terms.SiteMode.Planner')}
                                        </MenuItem>
                                    </Select>
                                }
                                label={
                                    <div style={{ display: 'flex' }}>
                                        {siteMode === 'PLANNER' && (
                                            <HtmlTooltip
                                                title={
                                                    <Typography color='inherit'>
                                                        Warning: You are currently in Planner mode. Any changes you make will not impact your
                                                        Dispatcher route plans
                                                    </Typography>
                                                }
                                            >
                                                <WarningAmber color={'warning'} />
                                            </HtmlTooltip>
                                        )}
                                        <b style={{ fontSize: '12px', marginTop: '3px' }}>&nbsp;{t('Global.Filters.Mode')}:&nbsp;</b>
                                    </div>
                                }
                                labelPlacement='start'
                            />
                        </FormControl>
                    )}
                </AppBar>

                <Outlet />
            </div>

            <EnterPlannerDialog
                openEnterPlannerDialog={openEnterPlannerDialog}
                openPlannerMode={openPlannerMode}
                routingProjectRouteList={routingProjectRouteList}
                projectName={projectName}
                shareProject={shareProject}
                projectRouteSelection={projectRouteSelection}
                routingProjects={routingProjects}
                projectSelection={projectSelection}
                projectServiceDay={projectServiceDay}
                handleProjectServiceDayChanged={handleProjectServiceDayChanged}
                setProjectSelection={setProjectSelection}
                setProjectRouteSelection={setProjectRouteSelection}
                setOpenPlannerMode={setOpenPlannerMode}
                handleChangeProjectName={handleChangeProjectName}
                handleChangeShareProject={handleChangeShareProject}
                handleEnterPlannerDialogClose={handleEnterPlannerDialogClose}
                setRefreshProject={setRefreshProject}
            />

            <SiteAlertDialog
                openAlert={openAlert}
                alertTitle={alertTitle}
                alertMessage={alertMessage}
                setOpenAlert={setOpenAlert}
                setAlertTitle={setAlertTitle}
                setAlertMessage={setAlertMessage}
            />
            <ROErrorDialog />
            <IdleTimeOutModal runningJob={runningJob} />
            {globalLoading && (
                <div id={'global_loading_spinner'}>
                    <CircularProgress disableShrink />
                </div>
            )}

            {window.location.pathname === '/map' ? (
                <footer>
                    <p className='credit'>
                        &#xA9; <span>{new Date().getFullYear()}</span> - RouteSmart Technologies, Inc.
                        {systemInfo && <SystemInfo systemInfo={systemInfo} />}
                    </p>
                </footer>
            ) : (
                <footer style={{ borderTop: '1px solid #ccc' }}>
                    <p
                        className='credit'
                        style={{ marginTop: '8px' }}
                    >
                        &#xA9; <span>{new Date().getFullYear()}</span> - RouteSmart Technologies, Inc.
                        {systemInfo && <SystemInfo systemInfo={systemInfo} />}
                    </p>
                </footer>
            )}
        </>
    );
};

const EnterPlannerDialog = (props) => {
    const {
        openEnterPlannerDialog,
        handleEnterPlannerDialogClose,
        setRefreshProject,
        projectServiceDay,
        openPlannerMode,
        setOpenPlannerMode,
        routingProjectRouteList,
        projectName,
        handleChangeProjectName,
        projectRouteSelection,
        setProjectRouteSelection,
        routingProjects,
        projectSelection,
        setProjectSelection,
        handleProjectServiceDayChanged,
        shareProject,
        handleChangeShareProject,
    } = props;

    const { t } = useTranslation();

    React.useEffect(() => {
        if (openEnterPlannerDialog) {
            setRefreshProject(true);
        }
    }, [openEnterPlannerDialog]);

    const defaultServiceDayList = [
        { value: 0, label: t('Global.DayOfWeek.AllDays'), display: true },
        { value: 1, label: t('Global.DayOfWeek.Monday'), display: true },
        { value: 2, label: t('Global.DayOfWeek.Tuesday'), display: true },
        { value: 3, label: t('Global.DayOfWeek.Wednesday'), display: true },
        { value: 4, label: t('Global.DayOfWeek.Thursday'), display: true },
        { value: 5, label: t('Global.DayOfWeek.Friday'), display: true },
        { value: 6, label: t('Global.DayOfWeek.Saturday'), display: true },
        { value: 7, label: t('Global.DayOfWeek.Sunday'), display: true },
    ];

    return (
        <Dialog
            open={openEnterPlannerDialog}
            maxWidth={'md'}
            id={'EnterPlannerDialog'}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {openPlannerMode !== null ? openPlannerMode + ' PROJECT' : 'WARNING'}
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleEnterPlannerDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='map-alert-description'>
                    You are entering the Planner Mode. Work performed in the Planner Mode will not impact your Dispatcher route plans.
                </DialogContentText>
                <br />
                <Grid container>
                    <Grid
                        item
                        xs={openPlannerMode !== null ? 4 : 12}
                    >
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}
                        >
                            <Button
                                variant='outlined'
                                onClick={() => setOpenPlannerMode('CREATE')}
                                color='success'
                                sx={{ width: '175px' }}
                            >
                                Create Project
                            </Button>

                            <Button
                                variant='outlined'
                                onClick={() => setOpenPlannerMode('OPEN')}
                                sx={{ width: '175px' }}
                            >
                                Open Project
                            </Button>
                        </Stack>
                    </Grid>
                    {openPlannerMode === 'CREATE' && (
                        <Grid
                            item
                            xs={8}
                            sx={{ borderLeft: '1px solid #ccc' }}
                        >
                            <FormControlLabel
                                control={
                                    <TextField
                                        variant='outlined'
                                        value={projectName}
                                        onChange={handleChangeProjectName}
                                        sx={{ height: '30px', marginLeft: '10px' }}
                                    />
                                }
                                sx={{ height: '30px' }}
                                label={<b>Project Name: </b>}
                                labelPlacement='start'
                                id={'projectNameInput'}
                            />

                            <FormControl
                                style={{ margin: '10px 0' }}
                                variant='standard'
                            >
                                <FormGroup row>
                                    <FormControlLabel
                                        value='start'
                                        control={
                                            <div style={{ width: '223px', marginLeft: '18px' }}>
                                                <ReactSelect
                                                    className='basic-single'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name='Project_Serviceday_Select'
                                                    id='Project_Serviceday_Select'
                                                    value={
                                                        projectServiceDay
                                                            ? defaultServiceDayList.find((s) => s.value == projectServiceDay.value)
                                                            : null
                                                    }
                                                    onChange={handleProjectServiceDayChanged}
                                                    options={defaultServiceDayList}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            display: state.data.display ? '' : 'none',
                                                            padding: 5,
                                                        }),
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </div>
                                        }
                                        label={<b>Service Day:&nbsp;</b>}
                                        labelPlacement='start'
                                    />
                                </FormGroup>
                            </FormControl>

                            <div
                                id='ProjectSelectionTable'
                                style={{ maxWidth: '100%', padding: '5px', height: '280px' }}
                            >
                                <MaterialReactTable
                                    columns={[{ header: 'Routes', accessorKey: 'Id' }]}
                                    data={routingProjectRouteList}
                                    enableTopToolbar={false}
                                    enableStickyHeader={true}
                                    enableColumnFilters={false}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableColumnActions={false}
                                    enableFullScreenToggle={false}
                                    enableDensityToggle={false}
                                    enableHiding={false}
                                    enableRowSelection={true}
                                    selectAllMode={'all'}
                                    getRowId={(originalRow) => originalRow.Id}
                                    onRowSelectionChange={setProjectRouteSelection}
                                    initialState={{
                                        rowSelection: {},
                                        density: 'compact',
                                        showGlobalFilter: false,
                                        sorting: [{ id: 'Id', desc: false }],
                                    }}
                                    state={{
                                        rowSelection: projectRouteSelection,
                                    }}
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '260px' },
                                    }}
                                    muiSelectAllCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    muiSelectCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                />
                            </div>

                            <FormControlLabel
                                label={<span style={{ marginLeft: '10px' }}>Share project</span>}
                                style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                                control={
                                    <Checkbox
                                        name='anchorWithServiceDay'
                                        checked={shareProject}
                                        onChange={(e) => handleChangeShareProject(e)}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {openPlannerMode === 'OPEN' && (
                        <Grid
                            item
                            xs={8}
                            sx={{ borderLeft: '1px solid #ccc' }}
                        >
                            <div
                                id='ProjectSelectionTable'
                                style={{ maxWidth: '100%', padding: '5px', height: '280px' }}
                            >
                                <MaterialReactTable
                                    columns={[
                                        { header: 'Project Name', accessorKey: 'SandboxName' },
                                        { header: 'Date Modified', accessorKey: 'DateModified' },
                                    ]}
                                    data={routingProjects}
                                    enableTopToolbar={false}
                                    enableStickyHeader={true}
                                    enableColumnFilters={false}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableColumnActions={false}
                                    enableFullScreenToggle={false}
                                    enableDensityToggle={false}
                                    enableHiding={false}
                                    enableRowSelection={true}
                                    enableMultiRowSelection={false}
                                    enableSelectAll={false}
                                    getRowId={(originalRow) => originalRow.ID}
                                    onRowSelectionChange={setProjectSelection}
                                    initialState={{
                                        rowSelection: {},
                                        density: 'compact',
                                        showGlobalFilter: false,
                                        sorting: [{ id: 'SandboxName', desc: false }],
                                    }}
                                    state={{
                                        rowSelection: projectSelection,
                                    }}
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '260px' },
                                    }}
                                    muiSelectAllCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    muiSelectCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                {openPlannerMode === 'CREATE' && (
                    <Button
                        style={
                            !projectName || (projectRouteSelection && Object.keys(projectRouteSelection).length === 0)
                                ? { backgroundColor: '#fff' }
                                : { backgroundColor: '#ee8a1d', color: '#fff' }
                        }
                        disabled={!projectName || (projectRouteSelection && Object.keys(projectRouteSelection).length === 0)}
                        onClick={() => handleEnterPlannerDialogClose('CREATE')}
                    >
                        CREATE
                    </Button>
                )}
                {openPlannerMode === 'OPEN' && (
                    <Button
                        style={
                            projectSelection && Object.keys(projectSelection).length === 0
                                ? { backgroundColor: '#fff' }
                                : { backgroundColor: '#ee8a1d', color: '#fff' }
                        }
                        disabled={projectSelection && Object.keys(projectSelection).length === 0}
                        onClick={() => handleEnterPlannerDialogClose('OPEN')}
                    >
                        OPEN
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const { Option } = components;
const ServicePatternOption = (props) =>
    props.data.value === '0' ? (
        <Option {...props}>
            <svg
                height='10'
                width='13'
            >
                <circle
                    cx='5'
                    cy='6'
                    r='3.5'
                    stroke='black'
                    strokeWidth='1'
                    fill={props.data.icon}
                />
            </svg>
            &nbsp;
            {props.data.label}
        </Option>
    ) : (
        <Option {...props}>
            <svg
                height='10'
                width='13'
            >
                <polygon
                    points='5, 1.34 10, 10 0, 10'
                    strokeWidth='1'
                    fill={props.data.icon}
                />
            </svg>
            &nbsp;
            {props.data.label}
        </Option>
    );

const ServicePatternValueLabel = (props) =>
    props.data.value === '0' ? (
        <span>
            <svg
                height='10'
                width='13'
            >
                <circle
                    cx='5'
                    cy='6'
                    r='3.5'
                    stroke='black'
                    strokeWidth='1'
                    fill={props.data.icon}
                />
            </svg>
            {props.data.label}
        </span>
    ) : (
        <span>
            <svg
                height='10'
                width='13'
            >
                <polygon
                    points='5, 1.34 10, 10 0, 10'
                    strokeWidth='1'
                    fill={props.data.icon}
                />
            </svg>
            {props.data.label}
        </span>
    );

const SystemInfo = (props) => {
    const { systemInfo } = props;
    return (
        <HtmlTooltip
            title={
                <>
                    <tr>
                        <td>Admin API: </td>
                        <td>
                            v. {systemInfo.AdminWebApiBuildVersion} ({systemInfo.AdminWebApiBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                    <tr>
                        <td>Client API: </td>
                        <td>
                            v. {systemInfo.ClientWebApiBuildVersion} ({systemInfo.ClientWebApiBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                    <tr>
                        <td>Utility API: </td>
                        <td>
                            v. {systemInfo.UtilityWebApiBuildVersion} ({systemInfo.UtilityWebApiBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                    <tr>
                        <td>PM service: </td>
                        <td>
                            v. {systemInfo.ProcessManagerBuildVersion} ({systemInfo.ProcessManagerBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                    <tr>
                        <td>Proxy service: </td>
                        <td>
                            v. {systemInfo.ProxyBuildVersion} ({systemInfo.ProxyBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                    <tr>
                        <td>Web Site: </td>
                        <td>
                            v. {systemInfo.SiteBuildVersion} ({systemInfo.SiteBuildDate.replace('T', ' ').substring(0, 19)})
                        </td>
                    </tr>
                </>
            }
        >
            <span style={window.location.pathname === '/map' ? { right: '100px', position: 'absolute' } : { right: '20px', position: 'absolute' }}>
                RO v. {systemInfo.AdminWebApiBuildVersion} <InfoIcon style={{ fontSize: 14 }} />
            </span>
        </HtmlTooltip>
    );
};
