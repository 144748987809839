//React
import React, { useState, useRef, useCallback, useContext } from 'react';
//RPW
import {
    getFormattedTime,
    getFormattedHHMM,
    formatAMPM,
    timeStringToFloat,
    defaultServiceDayList,
    ROUTE_SUMMARY_FIELDS,
    SCENARIO_ROUTE_SUMMARY_FIELDS,
    FloatToTimeString,
} from '../../_helpers/common';
import { PaperComponent, GlobalROContext } from '../common';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SendIcon from '@mui/icons-material/Send';
import EditNoteIcon from '@mui/icons-material/EditNote';

//DevExpress
import 'devextreme/dist/css/dx.light.compact.css';
import DataGrid, {
    Column,
    Scrolling,
    Sorting,
    Export,
    Summary,
    TotalItem,
    GroupPanel,
    Grouping,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    HeaderFilter,
    SearchPanel,
    ColumnChooser,
    ColumnFixing,
    Toolbar,
    Item,
    Format,
    StateStoring,
    ColumnChooserSearch,
    ColumnChooserSelection,
    Search,
    GroupItem,
    SortByGroupSummaryInfo,
} from 'devextreme-react/data-grid';
import { Button as DxButton, Popover } from 'devextreme-react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { checkFunction } from '../../_actions/Global.actions';
import MaterialReactTable from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';

export const RouteSummaryTable = (props) => {
    const {
        workspace,
        serviceDay,
        week,
        siteMode,
        routeViewTabCategorization,
        routeSummaryData,
        healthScore,
        routeViewTableData,
        setSandboxUpdateRouteStatsDialogOpen,
        setSandboxSolverDialogOpen,
        showReport,
        setShowReport,
        showTrips,
        setShowTrips,
        setShowSendSmartSuiteDialog,
        bottomHeight,
        setShowRouteIdEditor,
    } = props;

    const refRouteSummaryGrid = useRef();
    const { currencySetting } = useContext(GlobalROContext);

    const [data, setData] = useState([]);
    const [healthScoreData, setHealthScoreData] = useState({ Balance: 'NA', Compactness: 'NA', Interlacing: 'NA', Weighted: 'NA' });
    const [healthScoreDetailVisible, setHealthScoreDetailVisible] = useState(false);
    const [routeSummaryListView, setRouteSummaryListView] = useState('Summary');

    let currencySign = '$';
    if (currencySetting === 'EUR') {
        currencySign = '€';
    } else if (currencySetting === 'GBP') {
        currencySign = '£';
    }

    React.useEffect(() => {
        refreshRouteSummaryTable();
    }, [routeViewTabCategorization, routeViewTableData, routeSummaryData, week]);

    React.useEffect(() => {
        refreshWorkspaceServiceDayHealthScore();
    }, [healthScore]);

    React.useEffect(() => {
        ROUTE_SUMMARY_FIELDS.forEach((rf) => {
            if (routeSummaryListView === 'Summary' || routeSummaryListView === 'Modified') {
                refRouteSummaryGrid.current.instance.columnOption(rf.fieldName, 'visible', rf['defaultField']);
            } else if (routeSummaryListView === 'Productivity') {
                refRouteSummaryGrid.current.instance.columnOption(rf.fieldName, 'visible', rf['productivityField']);
            } else if (routeSummaryListView === 'CostSummary') {
                refRouteSummaryGrid.current.instance.columnOption(rf.fieldName, 'visible', rf['costField']);
            } else if (routeSummaryListView === 'All') {
                refRouteSummaryGrid.current.instance.columnOption(rf.fieldName, 'visible', true);
            }
        });
    }, [routeSummaryListView]);

    const refreshRouteSummaryTable = () => {
        let rsData = [...routeSummaryData];
        let routes = [];
        let sortedData = [];

        const hasMultiWeek = rsData.filter((r) => r.ServiceWeek === 0).length < rsData.length;

        if (routes.includes('No Route')) {
            routes.push('0');
        }
        if (serviceDay.value !== 0) {
            routes = structuredClone(routeViewTableData['R'])
                .filter((r) => r.checked === true)
                .map((r) => r.Id); //need adjustment here
            rsData = rsData.filter((r) => routes.includes(r.RouteID));
            const RT = structuredClone(routeViewTableData['R']).filter((r) => r.checked === true);
            rsData = rsData.map((rs) => {
                let target = RT.find((rt) => rt.Id === rs.RouteID);
                rs.Svg = target.Svg;
                return rs;
            });
            rsData = rsData.sort((a, b) => {
                return a.RouteID.localeCompare(b.RouteID, 'en', { numeric: true });
            });
        } else {
            if (routeViewTabCategorization.value.startsWith('R')) {
                let routes = structuredClone(routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                let result = [];
                routes.forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => r.Id);
                    const SD = r.subRows.filter((rs) => rs.checked === true);
                    let id = r.Id;
                    if (id === 'No Route') id = '0';
                    let sd = [...rsData].filter((s) => s.RouteID === id && selected.includes(s.ServiceDay));
                    sd = sd.map((sd) => {
                        let target = SD.find((t) => t.Id === sd.ServiceDay);
                        sd.Svg = target.Svg;
                        return sd;
                    });
                    result = [...result, ...sd];
                });
                rsData = [...result];
            } else {
                let routes = structuredClone(routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                let result = [];
                routes.forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => (r.Id === 'No Route' ? '0' : r.Id));
                    const RT = r.subRows.filter((rs) => rs.checked === true);
                    let sd = [...rsData].filter((s) => s.ServiceDay === r.Id && selected.includes(s.RouteID));
                    sd = sd.map((sd) => {
                        let target = RT.find((t) => t.Id === sd.RouteID);
                        sd.Svg = target.Svg;
                        return sd;
                    });
                    result = [...result, ...sd];
                });
                rsData = [...result];
            }
            rsData = rsData.sort((a, b) => {
                return a.RouteID.localeCompare(b.RouteID, 'en', { numeric: true });
            });
        }

        // week
        if (week) {
            rsData = rsData.filter((r) => r.ServiceWeek == week);
        }
        // filter out records with ServiceWeek = 0, if the data contains records with ServiceWeek other than 0.
        if (workspace.type === 'Multi-Day' && hasMultiWeek) {
            rsData = rsData.filter((r) => r.ServiceWeek !== 0);
        }

        rsData.forEach((item) => {
            let obj = { ...item };
            if (siteMode === 'DISPATCHER') {
                let totalTimeString = obj['TotalTimeTS'] ? obj['TotalTimeTS'].substring(0, obj['TotalTimeTS'].lastIndexOf(':')) : '';
                obj['TotalTimeTS'] = getFormattedHHMM(totalTimeString);
                obj['TotalTime'] = timeStringToFloat(obj['TotalTimeTS']);
            } else {
                obj['TotalTimeTS'] = FloatToTimeString(obj['TotalTime']);
            }
            obj['TravelTime'] = obj['Drive_Time'] + obj['Walk_Time'];
            obj['TotalStemDistance'] = obj['Start_stem_dist'] + obj['End_stem_dist'];
            obj['TotalSvcLocs'] = obj['SeqLoc'] + obj['UnSeqLoc'];
            obj['CrewCostPerHour'] =
                obj['CPerHour'] * (obj['COTStarts'] !== 0 && obj['TotalTime'] >= obj['COTStarts'] ? obj['COTStarts'] : obj['TotalTime']);
            obj['CrewCostPerHourOT'] =
                obj['CPerOTHour'] * (obj['COTStarts'] !== 0 && obj['TotalTime'] > obj['COTStarts'] ? obj['TotalTime'] - obj['COTStarts'] : 0);
            obj['CrewCostPerDemand'] = obj['CPerPiece'] * obj['Volume'];
            obj['CrewCostPerStop'] = obj['CPerStops'] * (obj['SeqLoc'] + obj['UnSeqLoc']);
            obj['CrewCostPerDistance'] = obj['CPerDist'] * obj['Total_Dist'];
            obj['CrewCostTotals'] =
                obj['CrewCostPerHour'] + obj['CrewCostPerHourOT'] + obj['CrewCostPerDemand'] + obj['CrewCostPerStop'] + obj['CrewCostPerDistance'];
            obj['VehCostFixed'] = obj['CPerFixed'];
            obj['VehCostVariable'] = obj['CPerVar'] * obj['Total_Dist'];
            obj['VehCostHourly'] = obj['CPerHourTruck'] * obj['TotalTime'];
            obj['VehCostTotals'] = obj['VehCostTotals'] = obj['VehCostFixed'] + obj['VehCostVariable'] + obj['VehCostHourly'];
            obj['TotalCost'] = obj['CrewCostTotals'] + obj['VehCostTotals'];
            obj['PerHourStops'] = obj['TotalTime'] === 0 ? 0 : obj['TotalSvcLocs'] / obj['TotalTime'];
            obj['PerHourQuantity'] = obj['TotalTime'] === 0 ? 0 : obj['Quantity'] / obj['TotalTime'];
            obj['PerHourVolume'] = obj['TotalTime'] === 0 ? 0 : obj['Volume'] / obj['TotalTime'];
            obj['PerDistanceStops'] = obj['Total_Dist'] === 0 ? 0 : obj['TotalSvcLocs'] / obj['Total_Dist'];
            obj['PerDistanceQuantity'] = obj['Total_Dist'] === 0 ? 0 : obj['Quantity'] / obj['Total_Dist'];
            obj['PerDistanceVolume'] = obj['Total_Dist'] === 0 ? 0 : obj['Volume'] / obj['Total_Dist'];
            obj['PerCostStops'] = obj['TotalSvcLocs'] === 0 ? 0 : obj['TotalCost'] / obj['TotalSvcLocs'];
            obj['PerCostQuantity'] = obj['Quantity'] === 0 ? 0 : obj['TotalCost'] / obj['Quantity'];
            obj['PerCostVolume'] = obj['Volume'] === 0 ? 0 : obj['TotalCost'] / obj['Volume'];
            obj['PerCostHour'] = obj['TotalTime'] === 0 ? 0 : obj['TotalCost'] / obj['TotalTime'];
            obj['PerCostDistance'] = obj['Total_Dist'] === 0 ? 0 : obj['TotalCost'] / obj['Total_Dist'];

            //Add new fields to hold converted time string
            obj['ServBaseTS'] = FloatToTimeString(obj['ServBase']);
            obj['TravelTimeTS'] = getFormattedTime(obj['TravelTime']);
            obj['WaitTimeTS'] = getFormattedTime(obj['WaitTime']);
            obj['ServExtraTS'] = getFormattedTime(obj['ServExtra']);
            obj['BreakTimeTS'] = getFormattedTime(obj['BreakTime']);
            obj['PreStartTmTS'] = getFormattedTime(obj['PreStartTm']);
            obj['PostEndTmTS'] = getFormattedTime(obj['PostEndTm']);
            obj['From_interTS'] = getFormattedTime(obj['From_inter']);
            obj['To_interTS'] = getFormattedTime(obj['To_inter']);
            obj['IntTimeTS'] = getFormattedTime(obj['IntTime']);
            sortedData.push(obj);
        });
        setData(sortedData);
    };

    const refreshWorkspaceServiceDayHealthScore = () => {
        if (serviceDay.value === 0) {
            setHealthScoreData({ Balance: 'NA', Compactness: 'NA', Interlacing: 'NA', Weighted: 'NA' });
        } else {
            if (siteMode === 'DISPATCHER') {
                if (healthScore) {
                    setHealthScoreData({
                        Balance: healthScore.Balance,
                        Compactness: healthScore.Compactness,
                        Interlacing: healthScore.Interlacing,
                        Weighted: healthScore.Weighted,
                    });
                } else {
                    setHealthScoreData({ Balance: 'NA', Compactness: 'NA', Interlacing: 'NA', Weighted: 'NA' });
                }
            } else {
                if (healthScore) {
                    setHealthScoreData({
                        Balance: healthScore.Balance,
                        Compactness: healthScore.Compactness,
                        Interlacing: healthScore.Interlacing,
                        Weighted: healthScore.Weighted,
                    });
                } else {
                    setHealthScoreData({ Balance: 'NA', Compactness: 'NA', Interlacing: 'NA', Weighted: 'NA' });
                }
            }
        }
    };

    const toggleDefault = () => {
        setHealthScoreDetailVisible(!healthScoreDetailVisible);
    };

    const getRouteSummaryFieldInfo = (dataField) => {
        const target = [...ROUTE_SUMMARY_FIELDS].find((f) => f.fieldName === dataField);
        switch (routeSummaryListView) {
            case 'Summary':
                return target.defaultField;
                break;
            case 'Modified':
                return target.defaultField;
                break;
            case 'Productivity':
                return target.productivityField;

                break;
            case 'CostSummary':
                return target.costField;

                break;
            case 'All':
                return true;

                break;
            default:
                return true;
        }
    };

    const getRouteSummaryFieldIndex = (dataField) => {
        if (routeSummaryListView === 'CostSummary') {
            if (dataField === 'TotalCost') {
                return ROUTE_SUMMARY_FIELDS.length;
            }
        }
        return ROUTE_SUMMARY_FIELDS.findIndex((field) => field.fieldName === dataField);
    };

    const loadState = useCallback(() => {
        var state = localStorage.getItem('routeSummaryGridLayout');
        if (state) {
            state = JSON.parse(state);
            state.columns = state.columns.map((s) => {
                s.groupIndex = null;
                if (s.dataField === 'Invalid') {
                    s.visible = siteMode === 'PLANNER';
                }
                if (workspace.type === 'Multi-Day') {
                    if (serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('R')) {
                        if (s.dataField === 'RouteID') {
                            s.groupIndex = 0;
                        }
                        if (s.dataField === 'ServiceDay') {
                            s.groupIndex = 1;
                        }
                    } else if (serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('S')) {
                        if (s.dataField === 'ServiceDay') {
                            s.groupIndex = 0;
                        }
                        if (s.dataField === 'RouteID') {
                            s.groupIndex = 1;
                        }
                    } else if (serviceDay.value !== 0) {
                        if (s.dataField === 'RouteID') {
                            s.groupIndex = 0;
                        }
                    }
                } else if (workspace.type === '') {
                    if (serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('R')) {
                        if (s.dataField === 'RouteID') {
                            s.groupIndex = 0;
                        }
                    } else if (serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('S')) {
                        if (s.dataField === 'ServiceDay') {
                            s.groupIndex = 0;
                        }
                    }
                }
                return s;
            });
        }
        return state;
    }, []);

    const saveState = useCallback((state) => {
        localStorage.setItem('routeSummaryGridLayout', JSON.stringify(state));
    }, []);

    const setRouteColumnGroupIndex = () => {
        if (workspace.type === 'Multi-Day') {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    return 0;
                } else {
                    return 1;
                }
            } else {
                return 0;
            }
        } else if (workspace.type === '') {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    return 0;
                }
            }
        }
        return;
    };

    const setServiceDayColumnGroupIndex = () => {
        if (workspace.type === 'Multi-Day') {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('S')) {
                    return 0;
                } else {
                    return 1;
                }
            }
        } else if (workspace.type === '') {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('S')) {
                    return 0;
                }
            }
        }
        return;
    };

    return (
        <div>
            <Popover
                target='#healthScore'
                position='top'
                width={200}
                visible={healthScoreDetailVisible}
            >
                <li>
                    <b>Balance:</b> {healthScoreData.Balance ?? 'NA'}
                </li>
                <li>
                    <b>Efficiency:</b> {healthScoreData.Interlacing ?? 'NA'}
                </li>
                <li>
                    <b>Compactness:</b> {healthScoreData.Compactness ?? 'NA'}
                </li>
            </Popover>
            <DataGrid
                id={'routeSummaryGrid'}
                ref={refRouteSummaryGrid}
                style={{ height: bottomHeight }}
                dataSource={routeSummaryListView !== 'Modified' ? data : data.filter((d) => d.Invalid === 1)}
                //keyExpr='RouteID'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode={'widget'}
                columnMinWidth={50}
                columnAutoWidth={false}
                onExporting={(e) => {
                    const workbook = new Workbook();
                    const worksheet = workbook.addWorksheet('Main sheet');

                    e.component.columnOption('Invalid', 'visible', false);
                    e.component.columnOption('Symbol', 'visible', false);

                    exportDataGrid({
                        component: e.component,
                        worksheet,
                        autoFilterEnabled: true,
                        customizeCell: ({ gridCell, excelCell }) => {
                            if (gridCell.rowType === 'data') {
                                if (gridCell.column.dataField === 'TotalTimeTS' && gridCell.value && gridCell.value !== '') {
                                    excelCell.value = gridCell.value;
                                }
                            }
                            if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                                if (gridCell.column.dataField === 'TotalTimeTS') {
                                    excelCell.value = gridCell.value;
                                }
                            }
                        },
                    }).then(() => {
                        e.component.columnOption('Symbol', 'visible', true);
                        e.component.columnOption('Invalid', 'visible', siteMode === 'PLANNER');
                        workbook.xlsx.writeBuffer().then((buffer) => {
                            saveAs(
                                new Blob([buffer], { type: 'application/octet-stream' }),
                                `${workspace.label}_${serviceDay.label}_${routeSummaryListView}.xlsx`,
                            );
                        });
                    });
                    e.cancel = true;
                }}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder='Search...'
                />
                <HeaderFilter visible={true} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <FilterPanel visible={false} />
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <StateStoring
                    enabled={true}
                    type='custom'
                    customSave={saveState}
                    customLoad={loadState}
                />
                <Pager
                    visible={true}
                    displayMode={'full'}
                    showPageSizeSelector={false}
                    showInfo={false}
                    showNavigationButtons={false}
                />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                >
                    <ColumnChooserSearch enabled={true} />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <ColumnFixing enabled={true} />
                <Scrolling mode='virtual' />
                <Sorting
                    mode='multiple'
                    showSortIndexes='true'
                />
                <Export
                    enabled={true}
                    allowExportSelectedData={false}
                />
                <Toolbar>
                    <Item location='before'>
                        <div className='dx-fieldset-header'>&nbsp;{'Summary'}&nbsp;&nbsp;</div>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Summary'}
                            type={routeSummaryListView === 'Summary' ? 'success' : 'normal'}
                            onClick={() => {
                                setRouteSummaryListView('Summary');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Productivity'}
                            type={routeSummaryListView === 'Productivity' ? 'success' : 'normal'}
                            onClick={() => {
                                setRouteSummaryListView('Productivity');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Cost'}
                            type={routeSummaryListView === 'CostSummary' ? 'success' : 'normal'}
                            onClick={() => {
                                setRouteSummaryListView('CostSummary');
                            }}
                        ></DxButton>
                    </Item>
                    <Item
                        location='before'
                        visible={siteMode === 'PLANNER'}
                    >
                        <DxButton
                            text={'Modified (' + data.filter((d) => d.Invalid === 1).length + ')'}
                            type={routeSummaryListView === 'Modified' ? 'success' : 'normal'}
                            onClick={() => {
                                setRouteSummaryListView('Modified');
                            }}
                        />
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'All'}
                            type={routeSummaryListView === 'All' ? 'success' : 'normal'}
                            onClick={() => {
                                setRouteSummaryListView('All');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        &nbsp;Health Score: {healthScoreData.Weighted ?? 'NA'}&nbsp;
                        <DxButton
                            icon='info'
                            type={healthScoreDetailVisible ? 'success' : 'normal'}
                            id='healthScore'
                            onClick={toggleDefault}
                        />
                    </Item>

                    <Item
                        location='before'
                        name='groupPanel'
                    >
                        <div style={{ width: '20px' }} />
                    </Item>

                    <Item
                        location='before'
                        visible={siteMode === 'PLANNER'}
                    >
                        <DxButton
                            icon={'orderedlist'}
                            hint={'Update Route Statistics'}
                            onClick={() => {
                                setSandboxUpdateRouteStatsDialogOpen(true);
                            }}
                        ></DxButton>
                    </Item>

                    <Item
                        location='before'
                        visible={siteMode === 'PLANNER'}
                    >
                        <DxButton
                            icon={'video'}
                            hint={'Run Solver'}
                            onClick={() => {
                                setSandboxSolverDialogOpen(true);
                            }}
                        />
                    </Item>

                    <Item
                        location='before'
                        visible={checkFunction(213)}
                    >
                        <DxButton
                            icon='RS RS_REPORT RS_GRID_ICON'
                            style={{ fontSize: '10px' }}
                            hint={'Download Route Report'}
                            disabled={serviceDay.value === 0}
                            onClick={() => {
                                setShowReport(!showReport);
                            }}
                        ></DxButton>
                    </Item>

                    <Item location='before'>
                        <DxButton
                            icon='RS RT_TRIP RS_GRID_ICON'
                            type={showTrips ? 'success' : 'normal'}
                            hint={'Disposal Trip(s) Info'}
                            onClick={() => {
                                setShowTrips(!showTrips);
                            }}
                        ></DxButton>
                    </Item>

                    <Item
                        location='before'
                        visible={checkFunction(803)}
                    >
                        <DxButton
                            hint={'Send to SmartSuite'}
                            onClick={() => {
                                setShowSendSmartSuiteDialog(true);
                            }}
                        >
                            <SendIcon style={{ fontSize: 12 }} />
                        </DxButton>
                    </Item>

                    <Item
                        location='before'
                        visible={siteMode === 'PLANNER'}
                    >
                        <DxButton
                            hint={'Edit Route Ids'}
                            onClick={() => {
                                setShowRouteIdEditor(true);
                            }}
                        >
                            <EditNoteIcon style={{ fontSize: 12 }} />
                        </DxButton>
                    </Item>

                    <Item location='after'>
                        <DxButton
                            icon='eyeclose'
                            hint='Clear Filters'
                            onClick={() => {
                                refRouteSummaryGrid.current.instance.clearFilter();
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='columnChooserButton'
                    />
                    <Item
                        location='after'
                        name='exportButton'
                    />
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    dataField='Invalid'
                    caption={'!'}
                    fixed={true}
                    width={20}
                    minWidth={20}
                    allowSorting={false}
                    alignment={'center'}
                    visibleIndex={0}
                    visible={siteMode === 'PLANNER'}
                    showInColumnChooser={false}
                    allowReordering={false}
                    allowFiltering={false}
                    allowResizing={false}
                    headerCellRender={(e) => (
                        <span
                            title='Modified'
                            style={{ color: '#959595', fontWeight: '800' }}
                        >
                            !
                        </span>
                    )}
                    cellRender={(e) => {
                        if (e.data.Invalid === 1) {
                            return <span style={{ color: 'red', fontWeight: '800' }}>!</span>;
                        } else {
                            return '';
                        }
                    }}
                ></Column>
                <Column
                    dataField={'Symbol'}
                    caption={''}
                    visibleIndex={1}
                    fixed={true}
                    visible={getRouteSummaryFieldInfo('RouteID')}
                    allowSorting={false}
                    allowReordering={false}
                    allowResizing={false}
                    showInColumnChooser={false}
                    allowFiltering={false}
                    cellRender={(e) => {
                        if (e.data.Svg) {
                            return (
                                <div>
                                    <span dangerouslySetInnerHTML={{ __html: e.data.Svg }} />{' '}
                                </div>
                            );
                        } else {
                            return '';
                        }
                    }}
                    minWidth={20}
                    width={20}
                ></Column>
                <Column
                    dataField={'RouteID'}
                    caption={'Route'}
                    groupIndex={setRouteColumnGroupIndex()}
                    visibleIndex={2}
                    fixed={true}
                    visible={getRouteSummaryFieldInfo('RouteID')}
                    sortingMethod={(v1, v2) => {
                        if (v1 && v2) return v1.localeCompare(v2, 'en', { numeric: true });
                    }}
                    allowReordering={false}
                    showInColumnChooser={false}
                    cellRender={(e) => {
                        return e.data.RouteID === '0' || e.data.RouteID === 0 ? 'No Route' : e.data.RouteID;
                    }}
                    groupCellRender={(rowData) => {
                        return rowData.value == 0 ? 'Route: No Route' : 'Route: ' + rowData.value;
                    }}
                    minWidth={100}
                    width={200}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                {serviceDay.value === 0 && (
                    <Column
                        dataField={'ServiceDay'}
                        caption={'Service Day'}
                        visibleIndex={2}
                        fixed={true}
                        visible={serviceDay.value === 0}
                        allowReordering={false}
                        showInColumnChooser={false}
                        groupIndex={setServiceDayColumnGroupIndex()}
                        calculateCellValue={(rowData) => {
                            return defaultServiceDayList[rowData.ServiceDay].label;
                        }}
                        groupCellRender={(rowData) => {
                            return 'Service Day: ' + rowData.displayValue;
                        }}
                        minWidth={100}
                        width={100}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                )}
                {workspace.type === 'Multi-Day' && (
                    <Column
                        dataField={'ServiceWeek'}
                        caption={'Week'}
                        visibleIndex={2}
                        fixed={true}
                        visible={true}
                        allowReordering={false}
                        showInColumnChooser={false}
                        minWidth={100}
                        width={100}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                )}
                <Column
                    dataField='TotalTimeTS'
                    caption='Time'
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('TotalTimeTS')}
                    visible={getRouteSummaryFieldInfo('TotalTimeTS')}
                    minWidth={100}
                    width={200}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='Quantity'
                    caption='Lifts'
                    visibleIndex={getRouteSummaryFieldIndex('Quantity')}
                    visible={getRouteSummaryFieldInfo('Quantity')}
                    minWidth={100}
                    width={150}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='Volume'
                    caption='Demand'
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('Volume')}
                    visible={getRouteSummaryFieldInfo('Volume')}
                >
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='SeqLoc'
                    caption='Seq Stops'
                    minWidth={100}
                    width={100}
                    visibleIndex={getRouteSummaryFieldIndex('SeqLoc')}
                    visible={getRouteSummaryFieldInfo('SeqLoc')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='UnSeqLoc'
                    caption='UnSeq Stops'
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('UnSeqLoc')}
                    visible={getRouteSummaryFieldInfo('UnSeqLoc')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='Total_Dist'
                    caption='Distance'
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('Total_Dist')}
                    visible={getRouteSummaryFieldInfo('Total_Dist')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='IntTimeTS'
                    caption='Time at Intermediate Facility'
                    minWidth={200}
                    width={200}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('IntTime')}
                    visible={getRouteSummaryFieldInfo('IntTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.IntTime ? rowData.IntTimeTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='NumTrips'
                    caption='Trips'
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('NumTrips')}
                    visible={getRouteSummaryFieldInfo('NumTrips')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='PreStartTmTS'
                    caption='Pre Trip'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('PreStartTm')}
                    visible={getRouteSummaryFieldInfo('PreStartTm')}
                    calculateCellValue={(rowData) => {
                        return rowData.PreStartTm ? rowData.PreStartTmTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='PostEndTmTS'
                    caption='Post Trip'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('PostEndTm')}
                    visible={getRouteSummaryFieldInfo('PostEndTm')}
                    calculateCellValue={(rowData) => {
                        return rowData.PostEndTm ? rowData.PostEndTmTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='To_interTS'
                    caption='Time to Intermediate Facility'
                    minWidth={200}
                    width={200}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('To_inter')}
                    visible={getRouteSummaryFieldInfo('To_inter')}
                    calculateCellValue={(rowData) => {
                        return rowData.To_inter ? rowData.To_interTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='From_interTS'
                    caption='Time from Intermediate Facility'
                    minWidth={200}
                    width={200}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('From_inter')}
                    visible={getRouteSummaryFieldInfo('From_inter')}
                    calculateCellValue={(rowData) => {
                        return rowData.From_inter ? rowData.From_interTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='StartTime'
                    caption='Start Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('StartTime')}
                    visible={getRouteSummaryFieldInfo('StartTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.StartTime ? formatAMPM(getFormattedTime(rowData.StartTime)) : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='EndTime'
                    caption='End Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('EndTime')}
                    visible={getRouteSummaryFieldInfo('EndTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.EndTime ? formatAMPM(getFormattedTime(rowData.EndTime)) : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='TravelTimeTS'
                    caption='Travel Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('TravelTime')}
                    visible={getRouteSummaryFieldInfo('TravelTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.TravelTime ? rowData.TravelTimeTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='ServBaseTS'
                    caption='Service Time'
                    minWidth={150}
                    width={200}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('ServBase')}
                    visible={getRouteSummaryFieldInfo('ServBase')}
                    calculateCellValue={(rowData) => {
                        return rowData.ServBase ? rowData.ServBaseTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='WaitTimeTS'
                    caption='Wait Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('WaitTime')}
                    visible={getRouteSummaryFieldInfo('WaitTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.WaitTime ? rowData.WaitTimeTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='ServExtraTS'
                    caption='Extra Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('ServExtra')}
                    visible={getRouteSummaryFieldInfo('ServExtra')}
                    calculateCellValue={(rowData) => {
                        return rowData.ServExtra ? rowData.ServExtraTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='ServDist'
                    caption='Extra Distance'
                    minWidth={120}
                    width={120}
                    visibleIndex={getRouteSummaryFieldIndex('ServDist')}
                    visible={getRouteSummaryFieldInfo('ServDist')}
                >
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='BreakTimeTS'
                    caption='Break Time'
                    minWidth={100}
                    width={100}
                    alignment='right'
                    visibleIndex={getRouteSummaryFieldIndex('BreakTime')}
                    visible={getRouteSummaryFieldInfo('BreakTime')}
                    calculateCellValue={(rowData) => {
                        return rowData.BreakTime ? rowData.BreakTimeTS : '';
                    }}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='StartLoc'
                    caption='Start Facility'
                    minWidth={100}
                    width={100}
                    visibleIndex={getRouteSummaryFieldIndex('StartLoc')}
                    visible={getRouteSummaryFieldInfo('StartLoc')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='EndLoc'
                    caption='End Facility'
                    minWidth={100}
                    width={100}
                    visibleIndex={getRouteSummaryFieldIndex('EndLoc')}
                    visible={getRouteSummaryFieldInfo('EndLoc')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='Start_stem_dist'
                    caption='Start Stem Distance'
                    minWidth={150}
                    width={150}
                    visibleIndex={getRouteSummaryFieldIndex('Start_stem_dist')}
                    visible={getRouteSummaryFieldInfo('Start_stem_dist')}
                >
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='End_stem_dist'
                    caption='End Stem Distance'
                    minWidth={150}
                    width={150}
                    visibleIndex={getRouteSummaryFieldIndex('End_stem_dist')}
                    visible={getRouteSummaryFieldInfo('End_stem_dist')}
                >
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='TotalStemDistance'
                    caption='Total Stem Distance'
                    minWidth={150}
                    width={150}
                    visibleIndex={getRouteSummaryFieldIndex('TotalStemDistance')}
                    visible={getRouteSummaryFieldInfo('TotalStemDistance')}
                >
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField='TotalSvcLocs'
                    caption='Stops'
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('TotalSvcLocs')}
                    visible={getRouteSummaryFieldInfo('TotalSvcLocs')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                </Column>

                <Column
                    dataField='TotalCost'
                    caption={`Cost (${currencySign})`}
                    minWidth={100}
                    width={200}
                    visibleIndex={getRouteSummaryFieldIndex('TotalCost')}
                    visible={getRouteSummaryFieldInfo('TotalCost')}
                >
                    <HeaderFilter>
                        <Search enabled={true} />
                    </HeaderFilter>
                    <Format
                        type='fixedPoint'
                        precision={2}
                    />
                </Column>

                {/* Per Hour */}
                <Column
                    caption={`Per Hour`}
                    alignment='center'
                    showInColumnChooser={true}
                    visibleIndex={getRouteSummaryFieldIndex('Per Hour')}
                    visible={getRouteSummaryFieldInfo('Per Hour')}
                >
                    <Column
                        dataField='PerHourStops'
                        caption='Stops'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerHourStops')}
                        visible={getRouteSummaryFieldInfo('PerHourStops')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerHourQuantity'
                        caption='Lifts'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerHourQuantity')}
                        visible={getRouteSummaryFieldInfo('PerHourQuantity')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerHourVolume'
                        caption='Demand'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerHourVolume')}
                        visible={getRouteSummaryFieldInfo('PerHourVolume')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </Column>
                {/* Per Distance */}
                <Column
                    caption={`Per Distance`}
                    alignment='center'
                    showInColumnChooser={true}
                    visibleIndex={getRouteSummaryFieldIndex('Per Distance')}
                    visible={getRouteSummaryFieldInfo('Per Distance')}
                >
                    <Column
                        dataField='PerDistanceStops'
                        caption='Stops'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerDistanceStops')}
                        visible={getRouteSummaryFieldInfo('PerDistanceStops')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerDistanceQuantity'
                        caption='Lifts'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerDistanceQuantity')}
                        visible={getRouteSummaryFieldInfo('PerDistanceQuantity')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerDistanceVolume'
                        caption='Demand'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerDistanceVolume')}
                        visible={getRouteSummaryFieldInfo('PerDistanceVolume')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </Column>
                {/* Cost Per */}
                <Column
                    caption={`Cost Per (${currencySign})`}
                    alignment='center'
                    showInColumnChooser={true}
                    visibleIndex={getRouteSummaryFieldIndex('Cost Per')}
                    visible={getRouteSummaryFieldInfo('Cost Per')}
                >
                    <Column
                        dataField='PerCostStops'
                        caption='Stop'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerCostStops')}
                        visible={getRouteSummaryFieldInfo('PerCostStops')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerCostQuantity'
                        caption='Lift'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerCostQuantity')}
                        visible={getRouteSummaryFieldInfo('PerCostQuantity')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PerCostVolume'
                        caption='Demand'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerCostVolume')}
                        visible={getRouteSummaryFieldInfo('PerCostVolume')}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                    </Column>
                    <Column
                        dataField='PerCostHour'
                        caption='Hour'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerCostHour')}
                        visible={getRouteSummaryFieldInfo('PerCostHour')}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                    </Column>
                    <Column
                        dataField='PerCostDistance'
                        caption='Distance'
                        minWidth={100}
                        width={100}
                        visibleIndex={getRouteSummaryFieldIndex('PerCostDistance')}
                        visible={getRouteSummaryFieldInfo('PerCostDistance')}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                    </Column>
                </Column>
                {/* Crew Cost */}
                <Column
                    caption={`Crew Cost (${currencySign})`}
                    alignment='center'
                    showInColumnChooser={true}
                    visibleIndex={getRouteSummaryFieldIndex('Crew Cost')}
                    visible={getRouteSummaryFieldInfo('Crew Cost')}
                >
                    <Column
                        dataField='CrewCostPerHour'
                        caption='Per Hour'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostPerHour')}
                        visible={getRouteSummaryFieldInfo('CrewCostPerHour')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CrewCostPerHourOT'
                        caption='Per Hour OT'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostPerHourOT')}
                        visible={getRouteSummaryFieldInfo('CrewCostPerHourOT')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CrewCostPerDemand'
                        caption='Per Demand'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostPerDemand')}
                        visible={getRouteSummaryFieldInfo('CrewCostPerDemand')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CrewCostPerStop'
                        caption='Per Stop'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostPerStop')}
                        visible={getRouteSummaryFieldInfo('CrewCostPerStop')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CrewCostPerDistance'
                        caption='Per Distance'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostPerDistance')}
                        visible={getRouteSummaryFieldInfo('CrewCostPerDistance')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CrewCostTotals'
                        caption='Totals'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('CrewCostTotals')}
                        visible={getRouteSummaryFieldInfo('CrewCostTotals')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </Column>
                {/* Vehicle Cost */}
                <Column
                    caption={`Vehicle Cost (${currencySign})`}
                    alignment='center'
                    showInColumnChooser={true}
                    visibleIndex={getRouteSummaryFieldIndex('Vehicle Cost')}
                    visible={getRouteSummaryFieldInfo('Vehicle Cost')}
                >
                    <Column
                        dataField='VehCostFixed'
                        caption='Daily'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('VehCostFixed')}
                        visible={getRouteSummaryFieldInfo('VehCostFixed')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='VehCostHourly'
                        caption='Hourly'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('VehCostHourly')}
                        visible={getRouteSummaryFieldInfo('VehCostHourly')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='VehCostVariable'
                        caption='Distance'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('VehCostVariable')}
                        visible={getRouteSummaryFieldInfo('VehCostVariable')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='VehCostTotals'
                        caption='Totals'
                        minWidth={100}
                        width={150}
                        visibleIndex={getRouteSummaryFieldIndex('VehCostTotals')}
                        visible={getRouteSummaryFieldInfo('VehCostTotals')}
                    >
                        <Format
                            type='fixedPoint'
                            precision={2}
                        />
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </Column>

                <Summary>
                    {/* Group Footer  */}
                    <GroupItem
                        column='TotalSvcLocs'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <GroupItem
                        column='Volume'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='Total_Dist'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }))}
                    />
                    <GroupItem
                        column='NumTrips'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <GroupItem
                        column='TotalCost'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='Quantity'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <GroupItem
                        column='PerHourStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerHourQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerHourVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerDistanceStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerDistanceQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerDistanceVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerCostStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <GroupItem
                        column='PerCostQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <GroupItem
                        column='PerCostVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <GroupItem
                        column='PerCostHour'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='PerCostDistance'
                        summaryType='avg'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <GroupItem
                        column='CrewCostPerHour'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='CrewCostPerHourOT'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='CrewCostPerDemand'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='CrewCostPerStop'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='CrewCostPerDistance'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='CrewCostTotals'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='VehCostFixed'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='VehCostHourly'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='VehCostVariable'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='VehCostTotals'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='SeqLoc'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <GroupItem
                        column='UnSeqLoc'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <GroupItem
                        column='ServDist'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='Start_stem_dist'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='End_stem_dist'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <GroupItem
                        column='TotalStemDistance'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <GroupItem
                        column='TotalTime'
                        showInColumn='TotalTimeTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='PreStartTm'
                        showInColumn='PreStartTmTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='TravelTime'
                        showInColumn='TravelTimeTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />

                    <GroupItem
                        column='ServBase'
                        showInColumn='ServBaseTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='WaitTime'
                        showInColumn='WaitTimeTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='ServExtra'
                        showInColumn='ServExtraTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='BreakTime'
                        showInColumn='BreakTimeTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='IntTime'
                        showInColumn='IntTimeTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='To_inter'
                        showInColumn='To_interTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='From_inter'
                        showInColumn='From_interTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <GroupItem
                        column='PostEndTm'
                        showInColumn='PostEndTmTS'
                        summaryType='sum'
                        showInGroupFooter={true}
                        alignByColumn={true}
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />

                    {/* Table Footer  */}
                    <TotalItem
                        column='RouteID'
                        summaryType='count'
                        displayFormat='{0}'
                        alignment='center'
                        customizeText={useCallback((e) => `Totals:`)}
                    />
                    <TotalItem
                        column='TotalSvcLocs'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <TotalItem
                        column='Quantity'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <TotalItem
                        column='Volume'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='TotalTime'
                        showInColumn='TotalTimeTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='Total_Dist'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }))}
                    />
                    <TotalItem
                        column='NumTrips'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <TotalItem
                        column='TotalCost'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <TotalItem
                        column='PerHourStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerHourQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerHourVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <TotalItem
                        column='PerDistanceStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerDistanceQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerDistanceVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <TotalItem
                        column='PerCostStops'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerCostQuantity'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerCostVolume'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerCostHour'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='PerCostDistance'
                        summaryType='avg'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />

                    <TotalItem
                        column='CrewCostPerHour'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='CrewCostPerHourOT'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='CrewCostPerDemand'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='CrewCostPerStop'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='CrewCostPerDistance'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='CrewCostTotals'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='VehCostFixed'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='VehCostHourly'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='VehCostVariable'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='VehCostTotals'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='SeqLoc'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <TotalItem
                        column='UnSeqLoc'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US'))}
                    />
                    <TotalItem
                        column='PreStartTm'
                        showInColumn='PreStartTmTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='TravelTime'
                        showInColumn='TravelTimeTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='ServBase'
                        showInColumn='ServBaseTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='WaitTime'
                        showInColumn='WaitTimeTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='ServExtra'
                        showInColumn='ServExtraTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='BreakTime'
                        showInColumn='BreakTimeTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='IntTime'
                        showInColumn='IntTimeTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='To_inter'
                        showInColumn='To_interTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='From_inter'
                        showInColumn='From_interTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='PostEndTm'
                        showInColumn='PostEndTmTS'
                        summaryType='sum'
                        alignment='right'
                        customizeText={useCallback((e) => getFormattedTime(e.value))}
                    />
                    <TotalItem
                        column='ServDist'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='Start_stem_dist'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='End_stem_dist'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                    <TotalItem
                        column='TotalStemDistance'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={useCallback((e) => e.value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                    />
                </Summary>
            </DataGrid>
        </div>
    );
};

export const ScenarioRouteSummaryTable = (props) => {
    const {
        workspace,
        selectedScenarioRoutes,
        scenarioRouteSummary,
        bottomHeight,
        showScenarioReport,
        setShowScenarioReport,
        setShowSendSmartSuiteDialog,
        selectedScenarioId,
    } = props;
    const [data, setData] = useState([]);

    React.useEffect(() => {
        refreshRouteSummaryTable();
    }, [workspace, selectedScenarioRoutes]);

    const refreshRouteSummaryTable = () => {
        let routeSummary = [];
        scenarioRouteSummary.map((item) => {
            if (item.RouteId === '') {
                item.RouteId = 'No Route';
            }
            if (selectedScenarioRoutes.includes(item.RouteId)) {
                routeSummary.push(item);
            }
        });
        setData(routeSummary);
    };

    const getRouteSummaryFieldInfo = (dataField) => {
        const target = SCENARIO_ROUTE_SUMMARY_FIELDS.find((f) => f.fieldName === dataField);
        return target.defaultField;
    };

    const getRouteSummaryFieldIndex = (dataField) => {
        return SCENARIO_ROUTE_SUMMARY_FIELDS.findIndex((field) => field.fieldName === dataField);
    };

    return (
        <div>
            <DataGrid
                style={{ height: bottomHeight }}
                dataSource={data}
                keyExpr='RouteId'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                onExporting={(e) => {
                    const workbook = new Workbook();
                    const worksheet = workbook.addWorksheet('Main sheet');
                    exportDataGrid({
                        component: e.component,
                        worksheet,
                        autoFilterEnabled: true,
                    }).then(() => {
                        workbook.xlsx.writeBuffer().then((buffer) => {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${workspace.value}_RouteSummary.xlsx`);
                        });
                    });
                    e.cancel = true;
                }}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder='Search...'
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <FilterPanel visible={false} />
                <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={100} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={false} />
                <Pager
                    visible={true}
                    displayMode={'full'}
                    showPageSizeSelector={false}
                    showInfo={false}
                    showNavigationButtons={false}
                />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                >
                    <ColumnChooserSearch enabled={true} />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <ColumnFixing enabled={true} />
                <Scrolling mode='virtual' />
                <Sorting
                    mode='multiple'
                    showSortIndexes='true'
                />
                <Export
                    enabled={true}
                    allowExportSelectedData={false}
                />
                <Toolbar>
                    <Item location='before'>
                        <div className='dx-fieldset-header'>&nbsp;{'Summary'}&nbsp;&nbsp;</div>
                    </Item>
                    <Item
                        location='before'
                        visible={checkFunction(213)}
                    >
                        <DxButton
                            icon='RS RS_REPORT RS_GRID_ICON'
                            style={{ fontSize: '10px' }}
                            hint={'Download Route Report'}
                            disabled={!selectedScenarioId}
                            onClick={() => {
                                setShowScenarioReport(!showScenarioReport);
                            }}
                        ></DxButton>
                    </Item>
                    <Item
                        location='before'
                        visible={checkFunction(803)}
                    >
                        <DxButton
                            hint={'Send to SmartSuite'}
                            disabled={!selectedScenarioId}
                            onClick={() => {
                                setShowSendSmartSuiteDialog(true);
                            }}
                        >
                            <SendIcon style={{ fontSize: 12 }} />
                        </DxButton>
                    </Item>
                    <Item
                        location='after'
                        name='columnChooserButton'
                    />
                    <Item
                        location='after'
                        name='exportButton'
                    />
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    dataField='RouteId'
                    caption='Route'
                    fixed={true}
                    visibleIndex={getRouteSummaryFieldIndex('RouteId')}
                    visible={getRouteSummaryFieldInfo('RouteId')}
                ></Column>
                <Column
                    dataField='StartTime'
                    caption='Start Time'
                    visibleIndex={getRouteSummaryFieldIndex('StartTime')}
                    visible={getRouteSummaryFieldInfo('StartTime')}
                ></Column>
                <Column
                    dataField='EndTime'
                    caption='End Time'
                    visibleIndex={getRouteSummaryFieldIndex('EndTime')}
                    visible={getRouteSummaryFieldInfo('EndTime')}
                ></Column>
                <Column
                    dataField='TotalTime'
                    caption='Total Time'
                    cellRender={(e) => {
                        const cellData = e.data.TotalTime;
                        return <span>{cellData ? getFormattedTime(cellData) : ''}</span>;
                    }}
                    visibleIndex={getRouteSummaryFieldIndex('TotalTime')}
                    visible={getRouteSummaryFieldInfo('TotalTime')}
                ></Column>
                <Column
                    dataField='ServiceTime'
                    caption='Service Time'
                    cellRender={(e) => {
                        const cellData = e.data.TotalTime;
                        return <span>{cellData ? getFormattedTime(cellData) : ''}</span>;
                    }}
                    visibleIndex={getRouteSummaryFieldIndex('ServiceTime')}
                    visible={getRouteSummaryFieldInfo('ServiceTime')}
                ></Column>
                <Column
                    dataField='StartLocation'
                    caption='Start Location'
                    visibleIndex={getRouteSummaryFieldIndex('StartLocation')}
                    visible={getRouteSummaryFieldInfo('StartLocation')}
                ></Column>
                <Column
                    dataField='EndLocation'
                    caption='EndLocation'
                    visibleIndex={getRouteSummaryFieldIndex('EndLocation')}
                    visible={getRouteSummaryFieldInfo('EndLocation')}
                ></Column>
                <Column
                    dataField='Demand'
                    caption='Total Demand'
                    visibleIndex={getRouteSummaryFieldIndex('Demand')}
                    visible={getRouteSummaryFieldInfo('Demand')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                </Column>
                <Column
                    dataField='Quantity'
                    caption='Total Lifts'
                    alignment='right'
                    cellRender={(e) => {
                        return e.data.Quantity || '0';
                    }}
                    visibleIndex={getRouteSummaryFieldIndex('Quantity')}
                    visible={getRouteSummaryFieldInfo('Quantity')}
                ></Column>
                <Column
                    dataField='SequencedLocations'
                    visibleIndex={getRouteSummaryFieldIndex('SequencedLocations')}
                    visible={getRouteSummaryFieldInfo('SequencedLocations')}
                ></Column>
                <Column
                    dataField='UnsequencedLocations'
                    visibleIndex={getRouteSummaryFieldIndex('UnsequencedLocations')}
                    visible={getRouteSummaryFieldInfo('UnsequencedLocations')}
                ></Column>
                <Column
                    dataField='TotalServiceLocations'
                    caption='Total Stops'
                    visibleIndex={getRouteSummaryFieldIndex('TotalServiceLocations')}
                    visible={getRouteSummaryFieldInfo('TotalServiceLocations')}
                ></Column>
                <Column
                    dataField='Distance'
                    caption='Total Distance'
                    visibleIndex={getRouteSummaryFieldIndex('Distance')}
                    visible={getRouteSummaryFieldInfo('Distance')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                </Column>
                <Column
                    dataField='StartStemDistance'
                    visibleIndex={getRouteSummaryFieldIndex('StartStemDistance')}
                    visible={getRouteSummaryFieldInfo('StartStemDistance')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                </Column>
                <Column
                    dataField='EndStemDistance'
                    visibleIndex={getRouteSummaryFieldIndex('EndStemDistance')}
                    visible={getRouteSummaryFieldInfo('EndStemDistance')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                </Column>
                <Column
                    dataField='TotalStemDistance'
                    visibleIndex={getRouteSummaryFieldIndex('TotalStemDistance')}
                    visible={getRouteSummaryFieldInfo('TotalStemDistance')}
                >
                    <Format
                        type='fixedPoint'
                        precision={1}
                    />
                </Column>
                <Summary>
                    <TotalItem
                        column='RouteId'
                        summaryType='count'
                        displayFormat='{0}'
                        alignment='right'
                        customizeText={(data) => {
                            return `Totals:`;
                        }}
                    />
                    <TotalItem
                        column='TotalTime'
                        showInColumn='TotalTime'
                        summaryType='sum'
                        alignment='right'
                        customizeText={(data) => {
                            return `${getFormattedTime(data.value)}`;
                        }}
                    />
                    <TotalItem
                        column='ServiceTime'
                        showInColumn='ServiceTime'
                        summaryType='sum'
                        alignment='left'
                        customizeText={(data) => {
                            return `${getFormattedTime(data.value)}`;
                        }}
                    />
                    <TotalItem
                        column='Demand'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='SequencedLocations'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='UnsequencedLocations'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='TotalServiceLocations'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='Distance'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='StartStemDistance'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='EndStemDistance'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='TotalStemDistance'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                    <TotalItem
                        column='Quantity'
                        valueFormat='#,###,###,##0.#'
                        summaryType='sum'
                        displayFormat='{0}'
                        alignment='right'
                    />
                </Summary>
            </DataGrid>
        </div>
    );
};

export const TripsInfoPanel = (props) => {
    const { showTrips, workspace, serviceDay, tripsData, siteMode, handleTripsPanelClose, routeViewTabCategorization, routeViewTableData } = props;

    const [data, setData] = useState([]);

    const { currencySetting } = useContext(GlobalROContext);

    let currencySign = '$';
    if (currencySetting === 'EUR') {
        currencySign = '€';
    } else if (currencySetting === 'GBP') {
        currencySign = '£';
    }

    let columns = [
        {
            header: 'Route',
            accessorKey: 'Route',
            size: 100,
            enableColumnOrdering: false,
            sortingFn: (rowA, rowB, columnId) => rowA.original.Route.localeCompare(rowB.original.Route, 'en', { numeric: true }),
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        },
        {
            header: 'Serivce Day',
            accessorKey: 'ServiceDayLabel',
            size: 100,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
            sortingFn: (rowA, rowB) => (rowA.ServiceDay < rowB.ServiceDay ? -1 : 1),
        },
        {
            header: 'Trips',
            accessorKey: 'Trip',
            size: 60,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        },
        {
            header: 'Facility Name',
            accessorKey: 'FacilityName',
            size: 100,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        },
        {
            header: 'Demand',
            accessorKey: 'Demand',
            size: 60,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
            Cell: ({ cell, row }) => {
                if (cell.getValue()) {
                    return <>{cell.getValue().toFixed(1)}</>;
                } else {
                    return '';
                }
            },
        },
        {
            header: 'Cost (' + currencySign + ')',
            accessorKey: 'Cost',
            size: 60,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
            Cell: ({ cell, row }) => {
                if (cell.getValue()) {
                    return <>{cell.getValue().toFixed(2)}</>;
                } else {
                    return '0.00';
                }
            },
        },
    ];

    React.useEffect(() => {
        let d = [...tripsData];
        if (serviceDay.value !== 0) {
            d = tripsData.filter((t) => t.ServiceDay === serviceDay.value);
            let selected = structuredClone(routeViewTableData['R'])
                .filter((r) => r.checked === true)
                .map((r) => r.Id); //need adjustment here
            if (selected && selected.length > 0) {
                d = d.filter((t) => selected.includes(t.Route));
            } else {
                d = [];
            }
        } else {
            if (routeViewTabCategorization.value.startsWith('R')) {
                let routes = structuredClone(routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                let result = [];
                routes.forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => r.Id);
                    let id = r.Id;
                    if (id === 'No Route') id = '0';
                    let sd = [...d].filter((s) => s.Route === id && selected.includes(s.ServiceDay));
                    result = [...result, ...sd];
                });
                d = [...result];
            } else {
                let routes = structuredClone(routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                let result = [];
                routes.forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => (r.Id === 'No Route' ? '0' : r.Id));
                    let sd = [...d].filter((s) => s.ServiceDay === r.Id && selected.includes(s.Route));
                    result = [...result, ...sd];
                });
                d = [...result];
            }
        }
        const result = d.map((t) => {
            if (t.ServiceDay !== undefined && t.ServiceDay !== null) {
                t.ServiceDayLabel = defaultServiceDayList.find((d) => d.value === t.ServiceDay)['label'];
            }
            return t;
        });
        setData(result);
    }, [tripsData, serviceDay, routeViewTabCategorization, routeViewTableData]);

    const handleExportData = () => {
        let result = [...data];
        result = result.map((r) => {
            r.ServiceDay = r.ServiceDayLabel;
            delete r.ServiceDayLabel;
            return r;
        });
        csvExporter.generateCsv(result);
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: `${workspace.label}_${serviceDay.label}_Disposal Trip(s) Info`,
        useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    return (
        <Dialog
            id={'TripsInfoDialog'}
            open={showTrips}
            onClose={handleTripsPanelClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            disableEnforceFocus
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '700px',
                    },
                },
                pointerEvents: 'none',
            }}
            PaperProps={{
                style: {
                    pointerEvents: 'auto',
                    position: 'absolute',
                    bottom: '225px',
                    left: '325px',
                },
            }}
        >
            <DialogTitle
                style={{ cursor: 'move', padding: '5px 24px' }}
                id='draggable-dialog-title'
            >
                Disposal Trip(s) Info
                <IconButton
                    aria-label='close'
                    onClick={handleTripsPanelClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '5px' }}>
                <div
                    id='tripsInfoDiv'
                    style={{ width: '650px', height: '250px', background: 'white' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableTopToolbar={true}
                        enableStickyHeader={true}
                        enableStickyFooter={false}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableRowSelection={false}
                        enableHiding={false}
                        positionToolbarAlertBanner='none'
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box>
                                <Button
                                    disabled={data.length === 0}
                                    onClick={handleExportData}
                                    variant='outlined'
                                    title='Export to CSV'
                                    style={{ minWidth: '35px', padding: 0 }}
                                >
                                    <FileDownloadIcon sx={{ fontSize: 20 }} />
                                </Button>
                            </Box>
                        )}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            columnOrder: ['Route', 'ServiceDayLabel', 'Trip', 'FacilityName', 'Demand', 'Cost'],
                            sorting: serviceDay.value === 0 ? [{ id: 'ServiceDayLabel', desc: false }] : [{ id: 'Route', desc: false }],
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '202px' },
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export const SmartSuiteDialog = (props) => {
    const { showSendSmartSuiteDialog, handleExportSmartSuiteClose } = props;

    return (
        <Dialog
            open={showSendSmartSuiteDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to send to SmartSuite?</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleExportSmartSuiteClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleExportSmartSuiteClose('SAVE')}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
